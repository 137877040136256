/* eslint-disable @typescript-eslint/no-empty-object-type */
/* eslint-disable @typescript-eslint/no-unsafe-function-type */
/* eslint-disable @typescript-eslint/no-wrapper-object-types */
/* eslint-disable no-irregular-whitespace */

/**
 *
 *            888
 *            888
 *            888
 *    .d8888b 888888 .d88b. 88888b.
 *    88K     888   d88""88b888 "88b
 *    "Y8888b.888   888  888888  888
 *         X88Y88b. Y88..88P888 d88P
 *     88888P' "Y888 "Y88P" 88888P"
 *                          888
 *                          888
 *                          888
 *
 *  Do not manually edit this file, if you need to update it see instructions in README.md (at root of repo)
 *
 *  Any changes you manually make will get lost when someone else updates it properly!
 *
 */

export interface FormInitializationParameter {
    initializationName?: string;
    isEnum?: boolean;
    isEntityId?: boolean;
    isPrimative?: boolean;
    inputType?: string;
}

export interface ExternalAppInfo {
    /** External portal form name */
    formName?: string;
    /** If app can be shared externally */
    canShareExternally?: boolean;
    /** If app has previously been shared in the portal */
    previouslyShared?: boolean;
}

export interface RoleSubType {
    /** Code */
    code?: string;
    /** Name */
    name?: string;
    /** Description */
    description?: string;
    role?: RoleType;
}

export interface RoleType {
    /** Name */
    name?: string;
    /** Code */
    code?: string;
    partyTypes?: string[];
    description?: string;
    isActive?: boolean;
    isPrimary?: boolean;
    isClient?: boolean;
    rolePrivileges?: string[];
}

export interface FormDocumentDefinition {
    name?: string;
    code?: string;
    categoryCode?: string;
    entityTypes?: SystemEntity[];
    formatsAccepted?: string[];
    isSignatureRequired?: boolean;
    guidance?: string;
}

export interface FormConditions {
    triggers?: Trigger[];
    calculations?: CalculatedField[];
    Initialisation?: Action;
    initialisationByMode?: Initialisation;
}

export interface FriendlyProblemDetails {
    errorCode?: UserErrorType;
    type?: string;
    title?: string;
    status?: number;
    detail?: string;
    instance?: string;
    extensions?: { [key: string]: any };
}

export interface LineChart {
    currency?: Currency;
    /** Date values for the x-axis */
    dates?: Date[];
    /** Values for each series entry */
    seriesValues?: ChartSeries[];
}

export interface ChartSeries {
    name?: string;
    data?: number[];
}

export interface QueryExpression {
    field: string;
    operator: FilterOperator;
    contextual?: boolean;
    values?: any[];
}

export interface CubeResult {
    rollupValue?: any;
    groups?: { [key: string]: any };
}

export interface CubeQuery {
    expressions?: QueryExpression[];
    rollupType?: QueryRollupType;
    rollupColumn?: string;
    interval?: any;
    groups?: string[];
}

export interface Theme {
    themeType?: string;
    fileIndex?: string;
    favIconFileIndex?: string;
    base?: Palette;
    light?: Palette;
    dark?: Palette;
}

export interface FileIndex {
    /** Unique index for a file - sha based on content */
    fileIndex?: string;
    /** Mime/Content-Type */
    contentType?: string;
    /** File Name */
    name?: string;
    /** Size in Bytes */
    sizeInBytes?: number;
    uploadedBy?: PartyStub;
    /** Uploaded Date */
    dateUploaded?: Date;
    /** Is the file stored in an external DMS */
    isExternal?: boolean;
    /** If the file is stored externally, this field defines which provider is used to get it */
    externalProvider?: string;
    /** If the file is stored externally, this field identifies it */
    externalIdentifier?: string;
    /** File could be a direct external URL instead of the above */
    url?: string;
}

export interface Palette {
    primary?: Colour;
    secondary?: Colour;
    success?: Colour;
    danger?: Colour;
    warning?: Colour;
    info?: Colour;
}

export interface Colour {
    r?: number;
    g?: number;
    b?: number;
}

export interface Action {
    recalculate?: string[];
    functions?: Function[];
    openUrls?: string[];
    updateFields?: UpdateField[];
    updateLabels?: UpdateLabel[];
    updateSections?: UpdateSection[];
    updateButtons?: UpdateButton[];
    showMessage?: ConditionalMessage[];
    hideMessage?: ConditionalMessage[];
}

export interface CodedReference {
    id?: string;
    type?: SystemEntity;
    /** Code */
    code?: string;
    /** Name */
    name?: string;
}

export interface AnswerValue {
    /** Type */
    type?: string;
    /** Value */
    value?: string;
    /** Label */
    label?: string;
}

export interface Document {
    id?: string;
    readonly documentId?: string;
    /** Name */
    name?: string;
    type?: CodedReference;
    documentType?: CodedReference;
    externalDocumentTypeCode?: string;
    category?: AnswerValue;
    /** Document Identifier */
    identifier?: string;
    fileIndex?: FileIndex;
    /** Metadata for the file */
    metadata?: string;
    uploadedBy?: PartyStub;
    /** Date Uploaded */
    dateUploaded?: Date;
    /** Expiry Date */
    dateExpires?: Date;
    /** Issued Date */
    dateIssued?: Date;
    /** If document has an expiry date and the expiry date is in the past */
    isExpired?: boolean;
    /** Is the document visible externally (e.g. on a portal) */
    isExternallyVisible?: boolean;
    /** Language Name */
    languageName?: string;
    /** Document issued place */
    placeOfIssue?: string;
    /** Document valid start date */
    validFrom?: Date;
    /** Document valid end date */
    validTo?: Date;
    sourceType?: DocumentSourceType;
    /** Signatured date */
    signatureDate?: Date;
    /** Additional comments */
    comment?: string;
    associations?: DocumentAssociation[];
    envelopes?: Envelope[];
    /** Issuing Country */
    countryCode?: string;
    integrationStatus?: IntegrationStatus;
}

export interface IntegrationStatus {
    status?: IntegrationStatusType;
    reason?: IntegrationStatusReason;
    message?: string;
    timestamp?: Date;
    correlationId?: string;
    externalSystemCode?: string;
    externalSystemIdentifier?: string;
}

export interface DocumentAssociation {
    id?: string;
    regarding?: Regarding;
    /** Date Created */
    dateCreated?: Date;
    createdBy?: PartyStub;
    /** Expiry Date */
    dateRemoved?: Date;
    removedBy?: PartyStub;
    /** If this document association was created by the system rather than manually added by a user.
For system-associated documents (e.g. added via a form), they should only be managed through that same route.
This is to avoid e.g. removing a document from a case after it was used to complete a work item. */
    isLocked?: boolean;
}

export interface Regarding {
    /** ID */
    id?: string;
    type?: SystemEntity;
    /** Name */
    name?: string;
    /** Where possible, if the Regarding object is ultimately linked to a party
Record the partyid here also (for ease of querying/reporting later) */
    partyId?: string;
}

export interface DocumentType {
    /** Code */
    id?: string;
    status?: DocumentTypeStatusType;
    /** Is the document type disabled (overrides to remove at a lower config level) */
    isActive?: boolean;
    /** Display Name */
    name?: string;
    /** Guidance Notes */
    guidance?: string;
    regulatoryDocumentType?: RegulatoryDocumentType;
    /** Category Code */
    categoryCode?: string;
    category?: AnswerValue;
    /** Code */
    code?: string;
    /** Legacy field */
    readonly externalCode?: string;
    /** The primary identity code of this document type. */
    primaryIdentity?: string;
    /** Is a signature required on the document? */
    isSignatureRequired?: boolean;
    /** What formats is the document accepted in? */
    formatsAccepted?: string[];
    /** The name of the form (used to capture metadata about the document) */
    formName?: string;
    /** The version of the form (used to capture metadata about the document) */
    formVersion?: number;
    /** Which entity types is this document type applicable to? */
    entityTypes?: SystemEntity[];
    /** Associated Booking Centres */
    bookingCentres?: CodedReference[];
    /** Associated Servicing Centres */
    servicingCentres?: CodedReference[];
}

export interface UpdateField {
    isRequired?: boolean;
    min?: number;
    max?: number;
    isDisabled?: boolean;
    options?: string[];
    guidanceText?: string;
    value?: any;
    lookupSources?: LookupSourceDefinition[];
    name?: string;
    isHidden?: boolean;
    label?: string;
}

export interface UpdateLabel {
    name?: string;
    isHidden?: boolean;
    label?: string;
}

export interface UpdateSection {
    guidanceText?: string;
    name?: string;
    isHidden?: boolean;
    label?: string;
}

export interface UpdateButton {
    isDisabled?: boolean;
    name?: string;
    isHidden?: boolean;
    label?: string;
}

export interface ConditionalMessage {
    name?: string;
}

export interface FormCompletionSummary {
    expected?: number;
    completed?: number;
    percentage?: number;
}

export interface FormAction {
    type?: FormActionType;
    name?: LocalisedString;
    function?: string;
}

export interface FormDataResult {
    /** Entity ID */
    entityId?: string;
    entityType?: SystemEntity;
    formName?: string;
    /** Version number of the form */
    formVersion?: number;
    /** What buttons to display at the top of the form */
    actions?: FormAction[];
    status?: FormDataStatus;
    /** The actual data */
    data?: any;
    /** Any previously published data */
    lastPublishedData?: any;
    lock?: FormLock;
    context?: FormContext;
    /** When this instance data was last saved */
    lastUpdatedOn?: Date;
    /** Who last updated it */
    lastUpdatedBy?: string;
    lastUpdatedByParty?: PartyStub;
    /** When it was published */
    publishedOn?: Date;
    /** Who published it */
    publishedBy?: string;
    completionSummary?: FormCompletionSummary;
    /** Pending Changes */
    pendingChanges?: FormPendingChange[];
}

export interface FormDataHistory {
    /** Unique Mongo ID */
    id?: string;
    /** Version number of the form */
    formVersion?: number;
    status?: FormDataStatus;
    /** When this instance data was last saved */
    updatedOn?: Date;
    /** Who updated it? */
    updatedBy?: string;
    updatedByParty?: PartyStub;
    /** When it was published */
    publishedOn?: Date;
    /** Who published it */
    publishedBy?: string;
}

export interface FormLock {
    /** Data Lock Record Id */
    entityId?: string;
    entityType?: SystemEntity;
    source?: SourceSystem;
    /** External identity */
    identity?: string;
    dataLockLevel?: DataLockLevel;
    displayText?: LocalisedString;
    /** Is the lock overrideable? */
    isOverridable?: boolean;
    /** The fields on the form that should be locked (if partial lock) */
    fields?: string[];
}

export interface SourceSystem {
    name?: string;
    code?: string;
}

export interface LocalisedString {
    key?: string;
    value?: string;
    parameters?: { [key: string]: any };
}

export interface FormPendingChange {
    type?: DataChangeType;
    status?: PendingChangeStatusType;
    action?: DataChangeActionType;
    field?: string;
    originalValue?: any;
    originalDisplayValue?: string;
    targetValue?: any;
    targetDisplayValue?: string;
}

export interface FormContext {
    appType?: AppType;
    appStatus?: AppStatusType;
    party?: Party;
    partyRole?: PartyRole;
    client?: Client;
    clientProduct?: ClientProduct;
    productGroup?: ProductGroup;
    process?: Process;
    case?: Case;
    bookingCentre?: string;
    servicingCentre?: string;
    dateStarted?: Date;
    dateFinished?: Date;
    dateLastUpdated?: Date;
    dateNextReviewDue?: Date;
    lastUpdatedBy?: PartyStub;
    userRoles?: string[];
    userPrivileges?: string[];
}

export interface FormDefinition {
    /** Name of thje Form */
    name?: string;
    /** Human Readable Description */
    description?: string;
    /** Version Number */
    version?: number;
    saveMode?: FormSaveMode;
    /** string array of save modes, for use on front end */
    readonly saveModes?: string[];
    app?: AppType;
    entityType?: SystemEntity;
    /** Can form data be deleted */
    canDelete?: boolean;
    /** Does this form support duplicate detection */
    duplicateDetection?: boolean;
    /** Does this form use the standard save/retrieve endpoint or have one specific to its type */
    standardEndpoint?: boolean;
    /** If a non-standard endpoint then what is the path - convention is then /path/{id} for GET, PUT (update), DELETE and /path for POST (create) */
    endpointPath?: string;
    /** Schema */
    schema?: FieldDefinition[];
    layout?: FormLayout;
    /** Hash of layout json - for comparing environment differences */
    layoutHash?: string;
    conditions?: FormConditions;
    /** Hash of conditions json - for comparing environment differences */
    conditionsHash?: string;
    /** A list of initialization parameters which can be used to inititalize a form */
    initializationParameters?: FormInitializationParameter[];
}

export interface FieldDefinition {
    name?: string;
    fieldType?: FormFieldType;
    isRequired?: boolean;
    isReadOnly?: boolean;
    isEncrypted?: boolean;
    isSensitive?: boolean;
    isObfuscated?: boolean;
    defaultValue?: any;
    options?: FieldDefinitionOption[];
    sortOptionsAlphabetically?: boolean;
    selectType?: string;
    selectSource?: SelectApiSource;
    lookupSources?: LookupSourceDefinition[];
    placeholder?: string;
    inputMaskRegEx?: string;
    inputMaskDescription?: string;
    min?: number;
    max?: number;
    dateRestrictionType?: DateRestrictionType;
    tagCategory?: TagCategory;
    previousTags?: string[];
    lookupCreationMode?: LookupCreationMode;
    lookupResultsMode?: LookupResultsMode;
    lookupContextMode?: LookupContextMode;
    lookupDefaultValue?: LookupDefault;
    documentTypeCode?: string;
    documentType?: FormDocumentDefinition;
    documentCategory?: string;
    formatsAccepted?: string;
    customEndpoint?: string;
    isPrimaryName?: boolean;
    summaryLevel?: SummaryLevel;
    summaryIcon?: string;
    isSortable?: boolean;
    quickCreateFormName?: string;
    minimumSearchCharacters?: number;
    contextualValues?: string[];
    contextualType?: string;
    answerSetCode?: string;
    formInitalisationParameters?: { [key: string]: string };
    childFormType?: string;
    childSchema?: FieldDefinition[];
    piiType?: PiiType;
}

export interface LookupSourceDefinition {
    type?: LookupSourceType;
    quickCreateFormName?: string;
}

export interface FormLayout {
    name?: string;
    label?: string;
    quickCreateEnabled?: boolean;
    sectionLayoutDefinitions?: FormSectionLayoutDefinition[];
    messageDefinitions?: FormMessageDefinition[];
}

export interface FormSectionLayoutDefinition {
    name: string;
    label?: string;
    guidanceText?: string;
    isHidden?: boolean;
    elementLayoutDefinitions?: FormElementLayoutDefinition[];
}

export interface FormElementLayoutDefinition {
    name: string;
    label?: string;
    elementType: FormElementType;
    elementStyle?: FormElementStyle;
    sectionLayoutDefinitions?: FormSectionLayoutDefinition[];
    guidanceText?: string;
    isHidden?: boolean;
    isDisabled?: boolean;
    min?: number;
    max?: number;
    isRequired?: boolean;
    placeholder?: string;
    lines?: number;
    forcesNewRow?: boolean;
    forcesFullWidth?: boolean;
    isLockable?: boolean;
    prefix?: string;
    suffix?: string;
}

export interface FormTemplateInstance {
    entityType?: SystemEntity;
    /** Name of the form */
    formName?: string;
    /** Version of the form */
    formVersion?: number;
    /** The actual data */
    data?: any;
}

export interface FormMessageDefinition {
    name: string;
    label: string;
}

export interface Initialisation {
    inAddMode?: Action;
    inEditMode?: Action;
    inDeleteMode?: Action;
    inSummaryMode?: Action;
}

export interface CalculatedField {
    field?: string;
    equalTo?: Calculation;
}

export interface Calculation {
    sum?: Sum;
    subtract?: Subtract;
    function?: string;
    decision?: string;
}

export interface Sum {
    of?: string[];
}

export interface Subtract {
    left?: string;
    right?: string;
}

export interface Trigger {
    field?: string;
    button?: string;
    link?: string;
    is?: Is[];
    changed?: Action;
    clicked?: Action;
}

export interface Is {
    equalTo?: any;
    anyOf?: string[];
    empty?: boolean;
    notEmpty?: boolean;
    future?: boolean;
    past?: boolean;
    lesserThan?: number;
    greaterThan?: number;
    then?: Action;
    else?: Action;
}

export interface FormFunctionResult {
    /** Changes to Data as result of function */
    dataChanges?: { [key: string]: any };
    /** Changes to schema as result of function */
    schemaChanges?: { [key: string]: FieldDefinition };
}

export interface FormTemplate {
    /** Code for the Template */
    code?: string;
    /** Name for the Template */
    name?: string;
    /** Description for the Template */
    description?: string;
    /** Form Name */
    formName?: string;
    /** Form Version */
    formVersion?: number;
}

export interface ClientSummary {
    clientId?: string;
    inceptionDate?: Date;
    clientStatus?: ClientStatusType;
    clientLifecycle?: ClientLifecycleStage;
    clientLifecycleDate?: Date;
}

export interface Party {
    /** Internal ID */
    id?: string;
    /** Name */
    name?: string;
    /** Short Name */
    shortName?: string;
    /** Addressee Name */
    addresseeName?: string;
    /** Is the party active? */
    isActive?: boolean;
    /** True if the calling user is in the parties's acls */
    isMine?: boolean;
    /** True if the party is locked */
    isLocked?: boolean;
    /** Is this party an advocate */
    isAdvocate?: boolean;
    /** Is this party related to a client? */
    isRelatedToClient?: boolean;
    owner?: PartyStub;
    status?: StatusType;
    partyType?: PartyType;
    country?: Country;
    currency?: Currency;
    /** Local Name */
    localeName?: string;
    mergedWith?: PartyStub;
    primaryAddress?: PostalAddress;
    /** Default Email Address */
    defaultEmailAddress?: string;
    /** Code */
    code?: string;
    avatarUrl?: string;
    /** Sum of all personal relationships (for all party roles) for the party
Only applies to people (i.e. not organisations) */
    numberOfPersonalConnections?: number;
    lastContacted?: ActivityStub;
    lastMeeting?: ActivityStub;
    nextMeeting?: ActivityStub;
    /** Which roles does this party play */
    partyRoles?: PartyRoleStub[];
    /** Foreign system identifiers */
    identities?: IdentityMap[];
    /** Primary Foreign system identifier */
    primaryIdentity?: string;
    /** Which contact points does this party have */
    contactPoints?: IContactPoint[];
    /** Number of linked completed activities (via activity party) */
    completedActivitiesCount?: number;
    clientStatus?: ClientSummary;
    openCases?: number;
    hasIssue?: boolean;
    hasOverdueCase?: boolean;
    netWorthClassification?: AnswerValue;
    introducingParty?: Reference;
    originator?: Reference;
    event?: AnswerValue;
    campaign?: AnswerValue;
    finsaClientCategory?: FinsaClientCategory;
    mifidClientCategory?: MifidClientCategory;
    mifidInvestorType?: MifidInvestorType;
    calculatedCrsStatus?: CrsStatusType;
    /** used to capture different names for a client relationship */
    otherNames?: string;
    /** Specify if the Party is considered as a US Tax Payer according to the FATCA. This has impact on decisions for Fatca Classification and also on the Document requirement decision */
    isUsTaxPayer?: boolean;
    usStatusComments?: string;
    mainActivityCountry?: Country;
    /** Describes how the relationship with the person has been sourced */
    originDetails?: string;
    relationships?: Relationship[];
    countryAssociations?: CountryAssociation[];
    nationalIdentifiers?: NationalIdentifier[];
    contactPreferences?: ContactPreference;
    financials?: financials3;
    qiStatus?: QiStatusType;
    qiStatusLastUpdateDate?: Date;
    fatcaClassificationValidityDate?: Date;
    statusValidationDate?: Date;
    statusEffectiveDate?: Date;
    taxRefund?: boolean;
}

export interface PartyRole {
    /** Internal ID */
    id?: string;
    /** Status */
    status?: string;
    /** Name */
    name?: string;
    /** Is this the primary role for the party? */
    isPrimary?: boolean;
    /** Is this role globally accessible? */
    isGloballyAccessible?: boolean;
    /** Is the country active? */
    isActive?: boolean;
    party?: PartyStub;
    owner?: PartyStub;
    role?: RoleType;
    subRole?: RoleSubType;
    clientStatus?: ClientSummary;
    /** Description of the role, usually used for job title */
    description?: string;
    client?: PartyClient;
    lastContacted?: ActivityStub;
    lastMeeting?: ActivityStub;
    nextMeeting?: ActivityStub;
    riskLevel?: Reference;
    /** True if the calling user is in the client's partyrole acls */
    isMine?: boolean;
    /** True if "locked" */
    isLocked?: boolean;
    /** Relationship Overview text description */
    relationshipOverview?: string;
    /** Managing Country (Jurisdiction) */
    managingCountry?: string;
    clientClassification?: ClientClassificationType;
    /** Foreign system identifiers */
    identities?: IdentityMap[];
    introducingAdviser?: Reference;
    introducingIntermediary?: Reference;
    introducingIntermediaryType?: IntroducingIntermediaryType;
    managingAdviser?: Reference;
    managingIntermediary?: Reference;
    managingIntermediaryType?: ManagingIntermediaryType;
    /** Primary Foreign system identifier */
    primaryIdentity?: string;

    [key: string]: any;
}

export interface Client {
    /** Internal ID */
    id?: string;
    /** Client code */
    code?: string;
    /** The name of the client */
    name?: string;
    /** The short name for the client */
    shortName?: string;
    /** Owning parties
For joint client this is related "joint acount holders"
For other cliens this is the connected party itself */
    holders?: PartyStub[];
    /** Status */
    status?: string;
    clientStatus?: ClientSummary;
    /** Is this the primary role for the party? */
    isPrimary?: boolean;
    /** Is this role globally accessible? */
    isGloballyAccessible?: boolean;
    /** Is a flight risk */
    isFlightRisk?: boolean;
    blockedStatus?: BlockedStatusType;
    blockedStatusReason?: string;
    owner?: PartyStub;
    /** Is the record active? */
    isActive?: boolean;
    party?: PartyStub;
    role?: RoleType;
    subRole?: RoleSubType;
    /** Description of the role, usually used for job title */
    description?: string;
    client?: PartyClient;
    lastContacted?: ActivityStub;
    lastMeeting?: ActivityStub;
    nextMeeting?: ActivityStub;
    riskLevel?: CodedReference;
    /** True if the calling user is in the client's partyrole acls */
    isMine?: boolean;
    /** True if "locked" */
    isLocked?: boolean;
    /** Relationship Overview text description */
    relationshipOverview?: string;
    /** Managing Country (Jurisdiction) */
    managingCountry?: string;
    clientClassification?: ClientClassificationType;
    introducingAdviser?: Reference;
    introducingIntermediary?: Reference;
    introducingIntermediaryType?: IntroducingIntermediaryType;
    managingAdviser?: Reference;
    managingIntermediary?: Reference;
    managingIntermediaryType?: ManagingIntermediaryType;
    bookingCentre?: CodedReference;
    servicingCentre?: CodedReference;
    relationships?: Relationship[];
    teamMembers?: Relationship[];
    subsidiaryTeams?: Relationship[];
    subsidiaries?: Relationship[];
    powerOfAttorneyFor?: Relationship[];
    children?: Relationship[];
    wards?: Relationship[];
    employees?: Relationship[];
    trustees?: Relationship[];
    directors?: Relationship[];
    shareholders?: Relationship[];
    correspondents?: Relationship[];
    settlors?: Relationship[];
    beneficiaries?: Relationship[];
    jointInvestors?: Relationship[];
    teamMembersOf?: Relationship[];
    parentTeams?: Relationship[];
    parentOrganisations?: Relationship[];
    powerOfAttorneys?: Relationship[];
    parents?: Relationship[];
    guardians?: Relationship[];
    employers?: Relationship[];
    trusteesOf?: Relationship[];
    directorsOf?: Relationship[];
    shareholdersOf?: Relationship[];
    correspondentsOf?: Relationship[];
    settlorsOf?: Relationship[];
    beneficiariesOf?: Relationship[];
    jointInvestorsOf?: Relationship[];
    advisers?: Relationship[];
    productProviders?: Relationship[];
    professionals?: Relationship[];
    friends?: Relationship[];
    spouses?: Relationship[];
    partners?: Relationship[];
    divorcees?: Relationship[];
    widowees?: Relationship[];
    familyMembers?: Relationship[];
    /** The date the last review was performed. */
    dateLastReview?: Date;
    /** The date the next review will be due. */
    dateNextReview?: Date;
    /** NUmber of days until next review */
    daysUntilNextReview?: number;
    /** The date the client officially became a client */
    inceptionDate?: Date;
    /** The date the client ceased to be a client */
    cessationDate?: Date;
    currency?: Currency;
    sourceOfFundsTotal?: CurrencyValue;
    totalPotentialWallet?: CurrencyValue;
    totalAum?: CurrencyValue;
    /** The date and time of the client aum value. */
    aumLatestDate?: Date;
    pipeline?: Pipeline;
    /** Does the client have open cases with issues? */
    hasCasesWithIssues?: boolean;
    /** Does the client have overdue cases? */
    hasOverdueCases?: boolean;
    /** Open cases count */
    openCases?: number;
    isFavourite?: boolean;
    /** Foreign system identifiers */
    identities?: IdentityMap[];
    /** Primary Foreign system identifier */
    primaryIdentity?: string;
    calculatedUsStatus?: UsStatus;
    /** True if the client is US-affiliated */
    usStatus?: boolean;
    unclaimedStatus?: AnswerValue;
    regulatoryClientCategory?: AnswerValue;
    fatcaClassification?: FatcaClassification;
    /** Whether a client is managed by an intermediary or not. This impacts the management and processes for managing the client */
    managedByIntermediary?: boolean;
    /** Politically Exposed Person - an Anti Money Laundering (AML) status. Banks have strong processes to monitor these PEPs, Annual Review as risk is high to do business with a PEP. */
    isPep?: boolean;
    /** Complex Structure flag */
    isComplexStructure?: boolean;
    calculatedAmlRiskRating?: CodedReference;
    validatedAmlRiskRating?: CodedReference;
    /** Whether the client is eligible for VAT or not. */
    calculatedVatStatus?: boolean;
    finsaClientCategory?: FinsaClientCategory;
    mifidClientCategory?: MifidClientCategory;
    mifidInvestorType?: MifidInvestorType;
    calculatedCrsStatus?: CrsStatusType;
    /** Other name used for the client */
    otherNames?: string;
    reportingName?: string;
    defaultTitle?: string;
    defaultSalutation?: string;
    defaultPostalAddress?: Reference;
    paymentPostalAddress?: Reference;
    defaultElectronicAddress?: Reference;
    numericNominative?: string;
    isStaffMember?: boolean;
    staffMemberType?: AnswerValue;
    guardianship?: AnswerValue;
    lsfinConfirmation?: AnswerValue;
    bankEntity?: AnswerValue;
    clientRelationshipVat?: boolean;
    qiCategory?: QiCategoryType;
    qiRecipientType?: QiRecipientType;
    qiExemptionType?: QiExemptionType;
    irsReportType?: AnswerValue;
    qiEntityType?: QiEntityType;
    fatcaEntityType?: FatcaEntityType;
    usInvestmentAuthorised?: boolean;
    claimOfTaxTreatyBenefits?: boolean;
    qiWithholding?: number;
    usAccountType?: UsAccountType;
    vatComment?: string;
    amlComments?: string;
    amlValidationStatus?: AnswerValue;
    complexStructureType?: AnswerValue;
    businessRiskLastUpdatedBy?: PartyStub;
    businessRiskLastUpdated?: Date;
    purposeKyc?: AnswerValue;
    conditionsOnboarding?: AnswerValue;
    bankOffice?: AnswerValue;
    purposeRelationship?: string;
    numericJustification?: string;
    percentageShares?: string;
    clientClassificationNote?: string;
    principleCompanyBusiness?: string;
    offStatistics?: boolean;
    thirdPartyBeneficialOwner?: boolean;
    riskRepresentative?: Reference;
    purposeOfLetter?: string;
    investmentAndFinancialObjectives?: string;
    riskProfile?: string;
    knowledgeAndExperience?: string;
    financialCircumstances?: string;
    investmentRecommendation?: string;
    capacityForLoss?: string;
    feesAndCharges?: string;
    financialPlanning?: string;
    vatApplicable?: boolean;
    /** Completed activities */
    completedActivitiesCount?: number;
    crsEntityType?: CrsEntityType;
    initialCashDealing?: CurrencyValue;
    mainCountryOfMoneyTransfer?: Country;
    mainCountryOfOriginOfMoneyTransfer?: Country;
    /** Does the client have adverse media converage. */
    adverseMediaCoverage?: boolean;
    cooperationWithAuthorities?: AnswerValue;
    typeOfAccount?: AnswerValue;
    /** Is the Client media exposed */
    isMediaExposed?: boolean;
    statusValidationDate?: Date;
    statusEffectiveDate?: Date;
    /** Onboarding bank country */
    onboardingBankCountry?: boolean;
    /** Onboarding different booking */
    onboardingDifferentBooking?: boolean;
    /** Id validity check */
    idValidityCheck?: boolean;
    /** Address validity check */
    addressValidityCheck?: boolean;
    /** Economic group Hr */
    economicGroupHr?: boolean;
    /** Economic group Pep */
    economicGroupPep?: boolean;
    /** Relationship manager comment */
    relationshipManagerComment?: string;
    /** Bank funds origin */
    bankFundsOrigin?: string;
    /** Funds Origin */
    fundsOrigin?: string;
    /** Other account */
    otherAccount?: string;
    uboNationality?: Country;
    referenceNationality?: Country;
    referenceResidence?: Country;
    /** Is inter company Vat applicable */
    isInterCompanyVatApplicable?: boolean;
    taxStatus?: AnswerValue;
    calculatedQiStatus?: CodedReference;
    validatedQiStatus?: CodedReference;
    /** Validated Qi Status */
    qiLastUpdated?: Date;
    /** Last suitability review date */
    lastSuitabilityReviewDate?: Date;
    /** Next suitability review date */
    nextSuitabilityReviewDate?: Date;
    /** Stamp exception */
    stampExemption?: boolean;
    financialFreezone?: AnswerValue;
    /** Origin of funds explanation */
    originOfFundExplanation?: string;
    /** Planned transactions */
    plannedTransactions?: string;
    /** Legal Conservation */
    legalConservation?: boolean;
    /** Legal Conservation start date */
    legalConservationStartDate?: Date;
    /** Legal Conservation end date */
    legalConservationEndDate?: Date;

    [key: string]: any;
}

export interface PartyStub {
    /** ID */
    id?: string;
    /** Name */
    name?: string;
    type?: SystemEntity;
    /** Avatar Token */
    avatarUrl?: string;
    partyType?: PartyType;
    identities?: IdentityMap[];
    /** Is the party active? */
    isActive?: boolean;
}

export interface ClientProduct {
    id?: string;
    /** Name */
    name?: string;
    reportingName?: string;
    /** The short name for the client product */
    shortName?: string;
    type?: ClientProductType;
    /** Code */
    code?: string;
    /** The index of client product */
    index?: number;
    dateOpened?: Date;
    dateClosed?: Date;
    dateLive?: Date;
    lastReviewDate?: Date;
    dateCreated?: Date;
    product?: Product;
    productAccess?: ClientProductAccess[];
    /** Purpose of the Client Product activity(ies) as an overall description */
    purpose?: string;
    currency?: Currency;
    /** The language used to to comminicate about this product. */
    language?: string;
    latestValue?: CurrencyValue;
    latestValueDate?: Date;
    estimatedValue?: CurrencyValue;
    estimatedValueDate?: Date;
    client?: Reference;
    owningParty?: PartyStub;
    originator?: PartyStub;
    owner?: PartyStub;
    parentAccount?: CodedReference;
    accounts?: ClientProduct[];
    groups?: ProductGroupSimple[];
    lastReviewedBy?: PartyStub;
    reviewStatus?: ClientProductReviewStatusType;
    reviewSummary?: string;
    app?: AppStatus;
    status?: ProductStatus;
    /** Whether the product is active or not */
    isActive?: boolean;
    /** Foreign system identifiers */
    identities?: IdentityMap[];
    /** Primary Foreign system identifier */
    primaryIdentity?: string;
    performanceCurrencyIso?: string;
    introducingParty?: PartyStub;
    blockedStatus?: BlockedStatusType;
    apiKey?: string;
    paymentAmount?: CurrencyValue;
    paymentBankAccount?: Reference;
    frequencyOfPayments?: string;
    portfolioInstructions?: string;
    payFullAmount?: boolean;
    introducingIntermediary?: Reference;
    introducingIntermediaryType?: IntroducingIntermediaryType;
    managingAdviser?: Reference;
    managingIntermediary?: Reference;
    managingIntermediaryType?: ManagingIntermediaryType;
    productProvider?: Reference;
    productProviderReferenceNumber?: string;
    productProviderContext?: ProductProviderContext;
    haveLetterOfAuthority?: boolean;
    isFactFindOnly?: boolean;
    clientProductRelationships?: ClientProductRelationshipModel[];
    associations?: CodedAssociation[];
    /** Is eligible for Unique-Client-View (VUC in French).  Identifies clients whose deposits are guaranteed by a special fund, in case of bank failure. */
    isUniqueClientViewEligible?: boolean;
    /** Is ready for Unique-Client-View (VUC in French).  Identifies clients whose deposits are guaranteed by a special fund, in case of bank failure. */
    isUniqueClientViewReady?: boolean;

    [key: string]: any;
}

export interface ProductGroup {
    id?: string;
    /** Name */
    name?: string;
    /** Code */
    code?: string;
    /** The default status type for all entities (inactive/active) */
    status?: string;
    groupType?: ProductGroupType;
    /** List of products in the group */
    clientProducts?: ClientProduct[];
    dateOpened?: Date;
    dateClosed?: Date;
    dateLive?: Date;
    currency?: Currency;
    latestValue?: CurrencyValue;
    client?: Reference;
    mandate?: CodedReference;
    objective?: CodedReference;
    feeStructure?: CodedReference;
    riskLevel?: CodedReference;
    benchmark?: CodedReference;
    serviceType?: ServiceType;
    timeHorizon?: InvestmentTimeHorizon;
    cherishedHoldings?: string;
    restrictions?: string;
    app?: AppStatus;
}

export interface Process {
    /** Internal ID */
    id?: string;
    /** Unique ID */
    uuid?: string;
    /** Is the process active? */
    isActive?: boolean;
    status?: ProcessStatusType;
    /** Task Name */
    subject: string;
    /** Date Started */
    dateStarted: Date;
    /** Date Completed */
    dateCompleted?: Date;
    /** Date Due */
    dateDue?: Date;
    /** Date the next active work item is due on */
    nextWorkItemDueDate?: Date;
    slaStatus?: SlaStatusType;
    completedStatus?: CompletedStatusType;
    processType?: ProcessTypeSummary;
    regarding?: Regarding;
    owner?: PartyStub;
    startedBy?: PartyStub;
    parentProcess?: Reference;
    case?: CaseStub;
    completedWorkItems?: number;
    totalWorkItems?: number;
    assignees?: PartyStub[];
}

export interface Case {
    /** Internal ID */
    id?: string;
    /** Unique ID */
    uuid?: string;
    /** Is the case active? */
    isActive?: boolean;
    status?: CaseStatusType;
    /** Task Name */
    subject: string;
    topic?: AnswerValue;
    description?: string;
    resolution?: string;
    /** The Internal Identifier */
    readonly internalIdentifier?: string;
    /** External Identifier */
    externalIdentifier?: string;
    originType?: CaseOriginType;
    /** Date Started */
    dateStarted: Date;
    /** Date Completed */
    dateCompleted?: Date;
    /** Date Due */
    dateDue?: Date;
    completedStatus?: CompletedStatusType;
    slaStatus?: SlaStatusType;
    caseType?: CaseTypeSummary;
    regarding?: Regarding;
    owner?: PartyStub;
    startedBy?: PartyStub;
    /** Priority */
    priority?: string;
    currencyValue?: CurrencyValue;
    isFavourite?: boolean;
    assignees?: CaseAssignee[];
    teamMembers?: PartyStub[];
    nextAction?: NextAction;
    originatingCase?: Reference;
    originatingOpportunity?: Reference;
    /** The originating system */
    originatingSystem?: string;
    /** Number of completed activities associated with this case */
    completedActivities?: number;
    /** Total number of activities associated with this case */
    totalActivities?: number;
    /** Total number of issues associated with this case */
    issueCount?: number;
    /** The nearest due date of any activity associated with this case */
    nextDueDate?: Date;
}

export interface MenuItem {
    icon?: string;
    label?: LocalisedString;
    url?: string;
}

export interface FieldDefinitionOption {
    value?: any;
    label?: string;
    active?: boolean;
    icon?: string;
    iconStack?: OptionComplexIcon[];
}

export interface OptionComplexIcon {
    icon?: string;
    style?: string;
    ratio?: number;
    tagColor?: number;
    textColor?: string;
    rotate?: number;
    offsetTop?: number;
    offsetLeft?: number;
    scale?: number;
}

export interface Currency {
    /** ISO Code */
    isoCode: string;
    /** Long Name, e.g. pounds sterling */
    name?: string;
    /** Symbol */
    symbol?: string;
}

export interface Locale {
    /** English name */
    englishName?: string;
    /** Native name (local language) */
    nativeName?: string;
    /** Name (e.g en-GB) */
    name?: string;
    /** LCID */
    lcid?: number;
    dateTimeFormat?: DateTimeFormat;
    numberFormat?: NumberFormat;
    /** Two Letter ISO Code for Region */
    twoLetterIsoRegionName?: string;
    /** Three Letter ISO Code for Language */
    threeLetterIsoLanguageName?: string;
    /** The default dialling prefix, e.g. +44 */
    defaultDiallingPrefix?: string;
    currency?: Currency;
}

export interface DateTimeFormat {
    /** Format to use for full date time */
    fullDateTimePattern?: string;
    /** Long date format */
    longDatePattern?: string;
    /** Long time format */
    longTimePattern?: string;
    /** Short Date pattern */
    shortDatePattern?: string;
    /** Short time pattern */
    shortTimePattern?: string;
}

export interface NumberFormat {
    /** Seperator between whole number and decimal part */
    numberDecimalSeparator?: string;
    /** Seperator for grouping whole number digits */
    numberGroupSeparator?: string;
    /** Currency Symbol */
    currencySymbol?: string;
}

export interface Currency {
    /** ISO Code */
    isoCode: string;
    /** Long Name, e.g. pounds sterling */
    name?: string;
    /** Symbol */
    symbol?: string;
}

export interface Country {
    /** Name */
    name?: string;
    localisedName?: LocalisedString;
    /** ISO Code */
    isoCode: string;
    /** Three Letter ISO Code */
    threeLetterIsoCode?: string;
    /** Currency */
    currencyCode?: string;
    /** The telephone dial code */
    dialCode?: string;
    /** Is a European Economic Member country */
    isEea?: boolean;
    /** Is a USA Economic Member country */
    isUsa?: boolean;
    /** Phone Regex */
    phoneRegex?: string;
    /** Mobile Regex */
    mobileRegex?: string;
}

export interface EntityAction {
    displayName?: LocalisedString;
    icon?: string;
    url?: string;
    type?: ActionType;
    isEnabled?: boolean;
    options?: EntityActionOption[];
    formInitialisationParameters?: { [key: string]: string; };
}

export interface EntityActionOption {
    /** Code */
    code?: string;
    displayName?: LocalisedString;
}

export interface UserTenant {
    tenantCode?: string;
    tenantName?: string;
    isDefault?: boolean;
}

export interface PostalAddressLookup {
    /** Unique identifier from the underlying address lookup provider */
    identifier?: string;
    /** The display text returned from the search (typically the address formatted) */
    displayText?: string;
}

export interface PostalAddressLookupResult {
    identifier?: string;
    summary?: string;
    streetBuildingIdentification?: string;
    buildingName?: string;
    street?: string;
    addressLine?: string;
    postOfficeBox?: string;
    department?: string;
    townLocationName?: string;
    city?: string;
    countryCode?: string;
    region?: string;
    postCode?: string;
}

export interface PostalAddress {
    /** Internal ID */
    id?: string;
    /** Name */
    name?: string;
    /** Is the country active? */
    isActive?: boolean;
    /** Status */
    status?: number;
    /** Is the overall preferred contact method for a party */
    isPreferred?: boolean;
    /** For a given type (e.g. phone), is the primary contact point */
    isPrimary?: boolean;
    contactPointType?: ContactPointType;
    addressType?: AddressType;
    /** Code (e.g. when sourced from an address lookup provider) */
    code?: string;
    /** Addressee */
    addressee?: string;
    /** Street Building Identification */
    streetBuildingIdentification?: string;
    /** street */
    street?: string;
    /** City */
    city?: string;
    /** region */
    region?: string;
    /** Post office Box */
    postOfficeBox?: string;
    /** PostCode / ZipCode */
    postCode?: string;
    country?: Country;
    geographicLocation?: GeographicLocation;
    party?: PartyStub;
    /** Start Date */
    startDate?: Date;
    /** End Date */
    endDate?: Date;
    /** Description */
    description?: string;
    /** Department */
    department?: string;
    /** Sub Department */
    subDepartment?: string;
    /** Room */
    room?: string;
    /** Town Location Name */
    townLocationName?: string;
    /** District Name */
    districtName?: string;
    /** Address Line */
    addressLine?: string;
    /** Building Name */
    buildingName?: string;
    /** Floor */
    floor?: string;
    singleLineFormatted?: string;
    multiLineFormatted?: string;
}

export interface GeographicLocation {
    /** Longitude */
    longitude?: number;
    /** Latitude */
    latitude?: number;
}

export interface ActivityStub {
    /** The unique identifier */
    id?: string;
    /** Name of subject of the activity */
    subject?: string;
    type?: ActivityType;
    /** When the activity occurs/occurred */
    date?: Date;
}

export interface IdentityMap {
    /** A unique reference for this identity map record */
    id?: string;
    target?: Regarding;
    source?: SourceSystem;
    /** External identity */
    identity?: string;
    /** Version of the record in the source system */
    version?: string;
    /** Is the record locked by the other system */
    isLocked?: boolean;
    /** Is this identitymap the primary identitymap for the entity */
    isPrimary?: boolean;
    dataLockLevel?: DataLockLevel;
}

export interface IContactPoint {
    id?: string;
    name?: string;
    isActive?: boolean;
    status?: number;
    isPreferred?: boolean;
    isPrimary?: boolean;
    startDate?: Date;
    endDate?: Date;
    description?: string;
    contactPointType?: ContactPointType;
    party?: PartyStub;
}

export interface PartyRoleStub {
    /** Internal ID */
    id?: string;
    /** Status */
    status?: string;
    /** Is this the primary role for the party? */
    isPrimary?: boolean;
    /** Is the country active? */
    isActive?: boolean;
    party?: PartyStub;
    owner?: PartyStub;
    role?: RoleType;
    subRole?: RoleSubType;
    clientStatusType?: ClientStatusType;
    clientLifecycleStage?: ClientLifecycleStage;
}

export interface Reference {
    /** ID */
    id?: string;
    type?: SystemEntity;
    /** Name */
    name?: string;
}

export interface NationalIdentifier {
    id?: string;
    party?: PartyStub;
    country?: Country;
    /** National Identifier Type */
    documentTypeCode?: string;
    /** National Identifier value e.g. passport number */
    identifier?: string;
    /** Is Primary */
    isPrimary?: boolean;
    /** Start Date */
    startDate?: Date;
    /** End Date */
    endDate?: Date;
    /** Comment */
    comment?: string;
    missingReason?: AnswerValue;
    noTinExplanation?: AnswerValue;
    /** Is this country CRS reportable? */
    isCrsReportable?: boolean;
}

export interface Envelope {
    id?: string;
    party?: PartyStub;
    client?: Reference;
    clientProduct?: Reference;
    case?: Reference;
    /** Name */
    name?: string;
    status?: EnvelopeStatusType;
    signatureProvider?: string;
    externalIdentifier?: string;
    due?: Date;
    completed?: Date;
    created?: Date;
    createdBy?: PartyStub;
    modified?: Date;
    modifiedBy?: PartyStub;
    sent?: Date;
    sentBy?: PartyStub;
    signatories?: Signatory[];
}

export interface Relationship {
    id?: string;
    isActive?: boolean;
    /** Date relationship started */
    dateFrom?: Date;
    /** Date relationship ended */
    dateTo?: Date;
    parentPartyRole?: PartyRoleStub;
    parentParty?: PartyStub;
    childPartyRole?: PartyRoleStub;
    childParty?: PartyStub;
    relatedPartyRole?: PartyRoleStub;
    relatedParty?: PartyStub;
    relationshipType?: RelationshipType;
    reciprocalRelationshipType?: RelationshipType;
    comment?: string;
    source?: AnswerValue;
    sourceComment?: string;
    isManagementPosition?: boolean;
}

export interface CountryAssociation {
    id?: string;
    party?: PartyStub;
    country?: Country;
    type?: CountryAssociationType;
    /** Is Primary */
    isPrimary?: boolean;
    /** Start Date */
    startDate?: Date;
    /** End Date */
    endDate?: Date;
    /** Comment */
    comment?: string;
}

export interface ContactPreference {
    /** Allow the Bank to use the email as a means of contact */
    allowEmail?: boolean;
    /** Allow the Bank to use the phone number as a means of contact */
    allowPhone?: boolean;
    allowSms?: boolean;
    allowMail?: boolean;
    /** Allow the Bank to send emails part of marketing campaigns */
    allowBulkEmail?: boolean;
    allowMobileChat?: boolean;
    /** Send Marketing Materials */
    sendMarketingMaterials?: boolean;
    preferredChannel?: ContactPointType;
    preferredLanguage?: string;
}

export interface PartyClient {
    /** Internal ID */
    id?: string;
    /** Name */
    name?: string;
    /** The date the last review was performed. */
    dateLastReview?: Date;
    /** The date the next review will be due. */
    dateNextReview?: Date;
    /** The date the client officially became a client */
    inceptionDate?: Date;
    /** The date the client ceased to be a client */
    cessationDate?: Date;
    currency?: Currency;
    totalAum?: CurrencyValue;
    pipelineWeightedValue?: CurrencyValue;
}

export interface CurrencyValue {
    /** Name is the currency's ISO code */
    isoCode?: string;
    value?: number;
    /** Value in the tenants base currency */
    baseValue?: number;
    formattedValue?: string;
}

export interface Pipeline {
    value?: CurrencyValue;
    weightedValue?: CurrencyValue;
    weightedValueConnected?: CurrencyValue;
    /** Number of entries in the pipeline */
    count?: number;
}

export interface Product {
    id?: string;
    /** Name */
    name?: string;
    status?: StatusType;
    /** Code */
    code?: string;
    provider?: Reference;
    productType?: ProductType;
    /** Parent Product */
    parentProductCode?: string;
    /** Colour code - maps to a theme "code" */
    colour?: string;
    /** Font-awesome icon name */
    icon?: string;
    /** Number of times this product has been used */
    usageCount?: number;
    /** Is this product from an external provider - e.g. not offered by this tenant, fact-find only. */
    isThirdParty?: boolean;
}

export interface ClientProductAccess {
    party?: PartyStub;
    /** Are they an "Owner" */
    isOwner?: boolean;
    /** Reason for access */
    reason?: string;
    role?: AnswerValue;
    addedBy?: Reference;
    /** When was it added? */
    addedOn?: Date;
}

export interface ExternalBankAccount {
    id?: string;
    /** Name */
    name?: string;
    sortCode?: string;
    accountNumber?: string;
    providerName?: string;
    iban?: string;
    swiftBic?: string;
    party?: PartyStub;
}

export interface CodedAssociation {
    /** ID */
    id?: string;
    type?: SystemEntity;
    /** Code */
    code?: string;
    /** Name */
    name?: string;
}

export interface ProductGroupSimple {
    id?: string;
    /** Name */
    name?: string;
    /** Code */
    code?: string;
    /** The default status type for all entities (inactive/active) */
    status?: string;
    groupType?: ProductGroupType;
    app?: AppStatus;
}

export interface AppStatus {
    /** Record ID */
    id?: string;
    /** Name */
    name?: string;
    type?: AppType;
    /** An icon to represent this app */
    icon?: string;
    status?: AppStatusType;
    ragStatus?: RAG;
    /** Next review date (if set) */
    nextReviewDate?: Date;
    party?: PartyStub;
    partyRole?: Reference;
    client?: Reference;
    clientProduct?: Reference;
    productGroup?: Reference;
    process?: Reference;
    case?: Reference;
    /** Document Requirement Categories */
    documentRequirementCategories?: string[];
    /** Form Name for the App */
    formName?: string;
    /** Form Version */
    formVersion?: number;
    /** Form data instance ID that completed the app */
    formUuid?: string;
    /** ID of the Entity for the FormData associated with this app */
    formDataEntityId?: string;
    /** Last updated on */
    lastUpdated?: Date;
    completionSummary?: FormCompletionSummary;
    /** Next review due date */
    nextReviewDue?: Date;
    lastUpdatedBy?: Reference;
    apps?: AppSubStatus[];
    /** Activities */
    activities?: ActivitySummary[];
    /** History */
    history?: HistoryEntry[];
    externalInformation?: ExternalAppInfo;
}

export interface ClientProductRelationshipModel {
    id?: string;
    partyRole?: Reference;
    party?: PartyStub;
    role?: string;
    scope?: AnswerValue;
    signatureMethod?: AnswerValue;
    numberOfPerson?: number;
    from?: Date;
    to?: Date;
    referenceNumber?: string;
    status?: ChildStatusType;
    hasElectronicBankingAccess?: boolean;
    canWithdrawCash?: boolean;
    canSubmitOrders?: boolean;
    canTransmitOrders?: boolean;
    hasPledgeRight?: boolean;
}

export interface CaseAssignee {
    /** ID */
    id?: string;
    /** Name */
    name?: string;
    /** Title */
    title?: string;
    /** Avatar Token */
    avatarUrl?: string;
    partyType?: PartyType;
    /** The number of open activities this person has assigned */
    numberOfOpenActivities?: number;
    /** The number of completed activities this person has assigned */
    numberOfCompletedActivities?: number;
    /** The next due date for work assigned to this person */
    nextDueDate?: Date;
    activityStatus?: ActivityStatusType;
}

export interface NextAction {
    /** ID */
    id?: string;
    activityType?: ActivityType;
    /** Subject */
    subject?: string;
    /** Number of issues flagged */
    issueCount?: number;
    slaStatus?: SlaStatusType;
    completedStatus?: CompletedStatusType;
    /** The due date */
    due?: Date;
    /** The assignees */
    assignees?: PartyStub[];
}

export interface Signatory {
    id?: string;
    party?: PartyStub;
    emailAddress?: string;
    envelope?: Reference;
    sentOn?: Date;
    createdOn?: Date;
    viewedOn?: Date;
    completedOn?: Date;
    session?: Reference;
    document?: Reference;
    /** The default status type for all entities (inactive/active) */
    status?: string;
    anchor?: string;
    action?: string;
}

export interface RelationshipType {
    /** Code */
    code?: string;
    /** Reciprocal Code */
    reciprocalCode?: string;
    /** Name */
    name?: string;
    /** Reciprocal Name */
    reciprocalName?: string;
    /** Description */
    description?: string;
    /** Is the role active? */
    isActive?: boolean;
    /** This is a system-defined role */
    isSystem?: boolean;
    /** Parent Party Types */
    parentPartyTypes?: PartyType[];
    /** Parent Role Type Code */
    parentRoleTypeCode?: string;
    /** Child Party Types */
    childPartyTypes?: PartyType[];
    /** Child Role Type Code */
    childRoleTypeCode?: string;
    /** When this is true, access flows from the parent party role to the child party role */
    cascadesAccessFromParent?: boolean;
    /** Used to denote employment relationships (e.g. mary works for abacus ltd)
(access for the child side of these relationships inherits from the parent side) */
    isEmployment?: boolean;
    /** Used to denote controlling relationships (e.g. trustee, director)
(access for the child side of these relationships inherits from the parent side) */
    isControlling?: boolean;
    /** Used to denote professional relationships (e.g. lawyer, accountant) */
    isProfessional?: boolean;
    /** Used to denote family relationships (e.g. spouse, child) */
    isFamily?: boolean;
    /** Should this relationship type be considered for family group members */
    includeInFamilyGroup?: boolean;
    /** Should this relationship type be considered for business group members */
    includeInBusinessGroup?: boolean;
    /** Should this relationship be cloned onto the self or primary role */
    cloneToSelf?: boolean;
    /** Does this have an inverse relationship? */
    hasReciprocal?: boolean;
    /** Is this the inverse relationship? */
    isReciprocal?: boolean;
    /** Used to denote the account holders (for Individual and Joint clients) */
    isAccountOwner?: boolean;
    level?: RelationshipLevel;
}

export interface ProductType {
    /** Code */
    code?: string;
    readonly name?: string;
    displayName?: LocalisedString;
    description?: string;
    needType?: string;
    colour?: string;
    icon?: string;
    formName?: string;
}

export interface AppSubStatus {
    /** Internal Id */
    id?: string;
    /** Name */
    name?: string;
    type?: AppType;
    /** Is the app visible on external apps? (e.g. mobile devices, portals) */
    isExternallyVisible?: boolean;
    /** Optionally, supply the code of the document-template that provides "information" about the app (in document, typically HTML, format) */
    documentTemplateCode?: boolean;
    /** An icon to represent this app */
    icon?: string;
    status?: AppStatusType;
    ragStatus?: RAG;
    party?: PartyStub;
    partyRole?: Reference;
    clientProduct?: Reference;
    productGroup?: Reference;
    /** Form Name for the App */
    formName?: string;
    /** ID of the Entity for the FormData associated with this app */
    formDataEntityId?: string;
    completionSummary?: FormCompletionSummary;
    /** Last updated on */
    lastUpdated?: Date;
    lastUpdatedBy?: Reference;
    /** Activities */
    activities?: ActivitySummary[];
    externalInformation?: ExternalAppInfo;
}

export interface ActivitySummary {
    /** Internal ID */
    id?: string;
    /** Is the activity active? */
    isActive?: boolean;
    /** Status */
    status?: string;
    /** The subject of the activity */
    subject?: string;
    /** Activity type e.g. PhoneCall = 1, Email = 2, Appointment = 3, Task = 4 */
    activityType?: string;
    /** Date scheduled to start */
    dateDue?: Date;
    /** Date activity actually happened */
    dateStart?: Date;
}

export interface Function {
    name?: string;
}

export interface HistoryEntry {
    eventType?: HistoryEventType;
    createdOn?: Date;
    createdBy?: PartyStub;
    target?: Regarding;
    related?: Regarding;
    data?: { [key: string]: any };
    description?: LocalisedString;
}

export interface ProcessTypeSummary {
    /** Code */
    code?: string;
    /** Name */
    name?: string;
    version?: number;
    isHidden?: boolean;
}

export interface CaseTypeSummary {
    /** Code */
    code?: string;
    /** Name */
    name?: string;
    /** Case type description */
    description?: string;
}

export interface CaseStub {
    /** Internal ID */
    id?: string;
    /** Is the case active? */
    isActive?: boolean;
    status?: CaseStatusType;
    /** The Internal Identifier */
    code?: string;
    /** Task Name */
    subject: string;
    caseType?: CaseTypeSummary;
    regarding?: Regarding;
    owner?: PartyStub;
}

export interface financials3 {
    EstimatedNetWealth?: CurrencyValue;
    PotentialValue?: CurrencyValue;
    PipelineValue?: CurrencyValue;
    PipelineWeightedValue?: CurrencyValue;
    PipelineWeightedValueConnected?: CurrencyValue;
    TotalAum?: CurrencyValue;
    TotalAumConnected?: CurrencyValue;
    CalculatedAssets?: CurrencyValue;
    CalculatedLiabilities?: CurrencyValue;
    CalculatedAnnualIncome?: CurrencyValue;
    CalculatedAnnualExpenses?: CurrencyValue;
    CalculatedNetWealth?: CurrencyValue;
    CalculatedTotalInvestments?: CurrencyValue;
    CalculatedPercentAssetsInvested?: CurrencyValue;
    CalculatedPercentWealthInvested?: CurrencyValue;
    CalculatedDiscretionarySurpusIncome?: CurrencyValue;
    CalculatedEmergencyCaseReserve?: CurrencyValue;
    CalculatedSurplusNetIncome?: CurrencyValue;
    TargetLifeCover?: CurrencyValue;
    CalculatedExistingLifeCover?: CurrencyValue;
    CalculatedLifeCoverShortfall?: CurrencyValue;
    TargetHealthCover?: CurrencyValue;
    CalculatedExistingHealthCover?: CurrencyValue;
    CalculatedHealthCoverShortfall?: CurrencyValue;
    CalculatedExistingIncomeCover?: CurrencyValue;
    CalculatedIncomeCoverShortfall?: CurrencyValue;
    CalculatedExistingMortgageRepayments?: CurrencyValue;
    CalculatedExistingMortgageCover?: CurrencyValue;
    CalculatedMortgageCoverShortfall?: CurrencyValue;
    EstimatedRequiredBuildingsCover?: CurrencyValue;
    CalculatedExistingBuildingsCover?: CurrencyValue;
    CalculatedBuildingsCoverShortfall?: CurrencyValue;
    EstimatedRequiredContentsCover?: CurrencyValue;
    CalculatedExistingContentsCover?: CurrencyValue;
    CalculatedContentsCoverShortfall?: CurrencyValue;
    EstimatedRequiredRetirementIncome?: CurrencyValue;
    EstimatedRequiredEmergencyFunds?: CurrencyValue;
    CalculatedCapitalForInvestment?: CurrencyValue;
    CalculatedLiquidAssets?: CurrencyValue;
    CalculatedEssentialExpenditure?: CurrencyValue;
    CalculatedProtection?: CurrencyValue;
}

export interface LookupFieldResult {
    /** The ID */
    id?: string;
    /** The display name */
    name?: string;
    /** Any "Code" field value */
    code?: string;
    lookupType?: LookupSourceType;
    partyType?: PartyType;
    /** Extra Description (where relevant) */
    description?: string;
    /** Avatar URL where applicable */
    avatarUrl?: string;
}

export enum ChildStatusType {
    Inactive = 'Inactive',
    Active = 'Active',
    Adding = 'Adding',
    Deleting = 'Deleting',
}

export enum PiiType {
    None = 'None',
    SearchableName = 'SearchableName',
    NameSearchRequest = 'NameSearchRequest',
    FileNameRequest = 'FileNameRequest',
    UrlRequest = 'UrlRequest',
    QueryRequest = 'QueryRequest',
    OpportunityQueryRequest = 'OpportunityQueryRequest',
    SearchableGlobal = 'SearchableGlobal',
    SearchableGlobalNoRegex = 'SearchableGlobalNoRegex',
    SearchableGlobalFile = 'SearchableGlobalFile',
    NonSearchableClient = 'NonSearchableClient',
    SearchableClient = 'SearchableClient',
    NonSearchableContactInformation = 'NonSearchableContactInformation',
    SearchableContactInformation = 'SearchableContactInformation',
    NonSearchableMisc = 'NonSearchableMisc',
    SearchableMisc = 'SearchableMisc',
    NonSearchableOperational = 'NonSearchableOperational',
    SearchableOperational = 'SearchableOperational',
    NonSearchableOrganisation = 'NonSearchableOrganisation',
    SearchableOrganisation = 'SearchableOrganisation',
    NonSearchableParty = 'NonSearchableParty',
    SearchableParty = 'SearchableParty',
    NonSearchablePerson = 'NonSearchablePerson',
    SearchablePerson = 'SearchablePerson',
    NonSearchableDate = 'NonSearchableDate',
    NonSearchableE164 = 'NonSearchableE164',
    NonSearchableDocument = 'NonSearchableDocument',
    NonSearchableEmail = 'NonSearchableEmail',
    NonSearchableIban = 'NonSearchableIban',
    NonSearchableLei = 'NonSearchableLei',
    LookUpUrlRequest = 'LookUpUrlRequest',
    LookUpRequest = 'LookUpRequest',
}

export enum LookupDefault {
    None = 'None',
    MyUser = 'MyUser',
    MyDefaultTeam = 'MyDefaultTeam',
    MyPerson = 'MyPerson',
    MyOrganisation = 'MyOrganisation',
}

export enum UserErrorType {
    AccessDenied = 'AccessDenied',
    MissingValue = 'MissingValue',
    DuplicateValue = 'DuplicateValue',
    FeatureNotAvailable = 'FeatureNotAvailable',
    InvalidState = 'InvalidState',
    InvalidRequest = 'InvalidRequest',
    InconsistentValue = 'InconsistentValue',
    IncompleteApps = 'IncompleteApps',
    UnresolvedIssues = 'UnresolvedIssues',
    AppNotConfirmable = 'AppNotConfirmable',
    CannotCompleteActivitiesScheduledInFuture = 'CannotCompleteActivitiesScheduledInFuture',
    InvalidData = 'InvalidData',
    CannotDeleteAllAccess = 'CannotDeleteAllAccess',
    DocumentAlreadyExists = 'DocumentAlreadyExists',
    PotentialDuplicates = 'PotentialDuplicates',
    DatabaseConstraintViolation = 'DatabaseConstraintViolation',
    MessageOnly = 'MessageOnly',
    RequiresComment = 'RequiresComment',
    RecordLocked = 'RecordLocked',
    RecordInUse = 'RecordInUse',
    TooManyFound = 'TooManyFound',
    Process = 'Process',
    Concurrency = 'Concurrency',
    Configuration = 'Configuration',
    RecordAlreadyPrimary = 'RecordAlreadyPrimary',
    DownstreamError = 'DownstreamError',
    Custom = 'Custom',
}

export enum PerformancePeriod {
    OneMonth = 'OneMonth',
    ThreeMonths = 'ThreeMonths',
    SixMonths = 'SixMonths',
    YearToDate = 'YearToDate',
    OneYear = 'OneYear',
    ThreeYear = 'ThreeYear',
    FiveYear = 'FiveYear',
    SinceInception = 'SinceInception',
}

export enum FatcaEntityType {
    LowValueFfi = 'LowValueFfi',
    NonProfitOrganization = 'NonProfitOrganization',
    ReportingModel1Ffi = 'ReportingModel1Ffi',
    ActiveNffe = 'ActiveNffe',
    ExemptRetirementPlans = 'ExemptRetirementPlans',
    SponsoredFfi = 'SponsoredFfi',
    PassiveNffeWithoutControllingUsPerson = 'PassiveNffeWithoutControllingUsPerson',
    OwnerDocumentedFfiWithoutUsOwner = 'OwnerDocumentedFfiWithoutUsOwner',
    RegisteredDeemedCompliantFfi = 'RegisteredDeemedCompliantFfi',
    InvestmentEntitiesWithoutFinancialAccounts = 'InvestmentEntitiesWithoutFinancialAccounts',
    LimitedLifeDebtInvestmentEntity = 'LimitedLifeDebtInvestmentEntity',
    ExceptedNonFinancialGroupEntity = 'ExceptedNonFinancialGroupEntity',
    RestrictedDistributor = 'RestrictedDistributor',
    NonreportingIgaFfi = 'NonreportingIgaFfi',
    PassiveNffeWithControllingUsPerson = 'PassiveNffeWithControllingUsPerson',
    DirectReportingNffe = 'DirectReportingNffe',
    OwnerDocumentedFfiWithUsOwner = 'OwnerDocumentedFfiWithUsOwner',
    EntityWhollyOwnedByExemptBeneficialOwners = 'EntityWhollyOwnedByExemptBeneficialOwners',
    InternationalOrganization = 'InternationalOrganization',
    SponsoredCloselyHeldInvestmentVehicle = 'SponsoredCloselyHeldInvestmentVehicle',
    PubliclyTradedNffeOrNffeAffiliateOfAPubliclyTradedCorporation = 'PubliclyTradedNffeOrNffeAffiliateOfAPubliclyTradedCorporation',
    ExceptedNonFinancialStartupCompany = 'ExceptedNonFinancialStartupCompany',
    SponsoredDirectReportingNffe = 'SponsoredDirectReportingNffe',
    ParticipatingFfi = 'ParticipatingFfi',
    ExceptedTerritoryNffe = 'ExceptedTerritoryNffe',
    ReportingModel2Ffi = 'ReportingModel2Ffi',
    TerritoryFinancialInstitution = 'TerritoryFinancialInstitution',
    ForeignGovernment = 'ForeignGovernment',
    ExceptedInterAffiliateFfi = 'ExceptedInterAffiliateFfi',
    SocialWelfareOrganization = 'SocialWelfareOrganization',
    NonFinancialAccount = 'NonFinancialAccount',
    NonParticipatingFfi = 'NonParticipatingFfi',
    NonRegisteringLocalBank = 'NonRegisteringLocalBank',
    ExemptRetirementPlanOfForeignGovernment = 'ExemptRetirementPlanOfForeignGovernment',
}

export enum QiEntityType {
    Corporation = 'Corporation',
    Partnership = 'Partnership',
    SimpleTrust = 'SimpleTrust',
    TaxExemptOrganization = 'TaxExemptOrganization',
    ComplexTrust = 'ComplexTrust',
    ForeignGovernmentControlledEntity = 'ForeignGovernmentControlledEntity',
    CentralBankOfIssue = 'CentralBankOfIssue',
    TerritoryFinancialInstitution = 'TerritoryFinancialInstitution',
    Estate = 'Estate',
    ForeignGovernmentIntegralPart = 'ForeignGovernmentIntegralPart',
    GrantorTrust = 'GrantorTrust',
    DisregardedEntity = 'DisregardedEntity',
    ForeignPrivateFoundation = 'ForeignPrivateFoundation',
    ForeignTaxExemptOrganization = 'ForeignTaxExemptOrganization',
    ForeignCentralBankOfIssue = 'ForeignCentralBankOfIssue',
    GovernmentOfAUsPossession = 'GovernmentOfAUsPossession',
    QI = 'QI',
    NonQualifiedIntermediary = 'NonQualifiedIntermediary',
    PrivateFoundation = 'PrivateFoundation',
    Usbranch = 'Usbranch',
    WithholdingForeignPartnership = 'WithholdingForeignPartnership',
    WithholdingForeignTrust = 'WithholdingForeignTrust',
    NonwithholdingForeignPartnership = 'NonwithholdingForeignPartnership',
    NonwithholdingForeignSimpleTrust = 'NonwithholdingForeignSimpleTrust',
    NonwithholdingForeignGrantorTrust = 'NonwithholdingForeignGrantorTrust',
    ForeignGovernment = 'ForeignGovernment',
    InternationalOrganization = 'InternationalOrganization',
}

export enum QiExemptionType {
    NotApplicable = 'NotApplicable',
    IncomeConnectedWithUsTradeOrBusiness = 'IncomeConnectedWithUsTradeOrBusiness',
    ExemptUnderInternalRevenueCode = 'ExemptUnderInternalRevenueCode',
    IncomeNotFromUsSources = 'IncomeNotFromUsSources',
    ExemptUnderTaxTreaty = 'ExemptUnderTaxTreaty',
    InterestExemptUnderInternalRevenueCode = 'InterestExemptUnderInternalRevenueCode',
    QualifiedIntermediaryThatAssumesPrimaryWithholdingResponsibility = 'QualifiedIntermediaryThatAssumesPrimaryWithholdingResponsibility',
    WitholdingForeignPartnership = 'WitholdingForeignPartnership',
    UbBranchTreatedAsUsPerson = 'UbBranchTreatedAsUsPerson',
    QualifiedIntermediaryRepresentsIncomeIsExempt = 'QualifiedIntermediaryRepresentsIncomeIsExempt',
    QualifiedSecuritiesLenderThatAssumesPrimaryWitholding = 'QualifiedSecuritiesLenderThatAssumesPrimaryWitholding',
    DirectPayeeNotSubjectToChapter4Withholding = 'DirectPayeeNotSubjectToChapter4Withholding',
    PayeesOfParticipatingFfiOrRegisteredFfi = 'PayeesOfParticipatingFfiOrRegisteredFfi',
}

export enum QiRecipientType {
    UsBranchTreatedAsUsPerson = 'UsBranchTreatedAsUsPerson',
    PartnershipOtherThanWithholdingForeignPartnerships = 'PartnershipOtherThanWithholdingForeignPartnerships',
    WithholdingForeignPartnerOrWithholdingForeignTrust = 'WithholdingForeignPartnerOrWithholdingForeignTrust',
    Trust = 'Trust',
    QualifiedIntermediary = 'QualifiedIntermediary',
    Corporation = 'Corporation',
    Individual = 'Individual',
    Estate = 'Estate',
    PrivateFoundation = 'PrivateFoundation',
    GovernmentOrInternationalOrganization = 'GovernmentOrInternationalOrganization',
    TaxExemptOrganization = 'TaxExemptOrganization',
    UnknownRecipient = 'UnknownRecipient',
    ArtistOrAthlete = 'ArtistOrAthlete',
    QualifiedIntermediaryWithRatePoolGeneral = 'QualifiedIntermediaryWithRatePoolGeneral',
    QualifiedIntermediaryWithRatePoolExOrg = 'QualifiedIntermediaryWithRatePoolExOrg',
    PrivateArrangIntermediaryWithRatePoolGeneral = 'PrivateArrangIntermediaryWithRatePoolGeneral',
    PrivateArrangIntermediaryWithRatePoolExOrg = 'PrivateArrangIntermediaryWithRatePoolExOrg',
    JointAccountWithholdingRatePool = 'JointAccountWithholdingRatePool',
    NonParticipatingFfiPool = 'NonParticipatingFfiPool',
    UsPayeesPool = 'UsPayeesPool',
}

export enum QiCategoryType {
    NonUsCorporation = 'NonUsCorporation',
    NonUsFinancialInstitution = 'NonUsFinancialInstitution',
    NonUsFoundation = 'NonUsFoundation',
    NonUsComplexTrust = 'NonUsComplexTrust',
    NonUsQualifiedIntermediary = 'NonUsQualifiedIntermediary',
    NonUsGrantorTrust = 'NonUsGrantorTrust',
    NonUsSimpleTrust = 'NonUsSimpleTrust',
    NonUsPartnership = 'NonUsPartnership',
    UsPartnership = 'UsPartnership',
    UsTrust = 'UsTrust',
    NonUsIndividual = 'NonUsIndividual',
    NonUsTaxExemptOrganization = 'NonUsTaxExemptOrganization',
    NonUsEstate = 'NonUsEstate',
    UsIndividual = 'UsIndividual',
    UsCorporation = 'UsCorporation',
    NonUsCentralBankOfIssue = 'NonUsCentralBankOfIssue',
    NonUsPrivateFoundation = 'NonUsPrivateFoundation',
    NonUsDisregardedEntity = 'NonUsDisregardedEntity',
    NonUsInternationalOrganization = 'NonUsInternationalOrganization',
    NonUsForeignGovernmentControlledEntity = 'NonUsForeignGovernmentControlledEntity',
    NonUsForeignGovernmentIntegralPart = 'NonUsForeignGovernmentIntegralPart',
    NonUsForeignGovernment = 'NonUsForeignGovernment',
    NonUsNonqualifiedIntermediary = 'NonUsNonqualifiedIntermediary',
    NonUsTerritoryFinancialInstitution = 'NonUsTerritoryFinancialInstitution',
    NonUsWithholdingForeignTrust = 'NonUsWithholdingForeignTrust',
    GovernmentOfUsPossession = 'GovernmentOfUsPossession',
    NonUsBrokers = 'NonUsBrokers',
    UsBrokers = 'UsBrokers',
    UsFinancialInstituations = 'UsFinancialInstituations',
    UsFoundations = 'UsFoundations',
    UsGrantorTrust = 'UsGrantorTrust',
    NonUsClearingOrganizations = 'NonUsClearingOrganizations',
    UsClearingOrganizations = 'UsClearingOrganizations',
    UsEstates = 'UsEstates',
    UsExempt = 'UsExempt',
    UsGovernements = 'UsGovernements',
    UsInternationalOrganizations = 'UsInternationalOrganizations',
    UsSoleProprietorships = 'UsSoleProprietorships',
}

export enum QiStatusType {
    Refused = 'Refused',
    Exempt = 'Exempt',
    Expired = 'Expired',
    Awaiting = 'Awaiting',
    NoW8 = 'NoW8',
    ValidW8Exp = 'ValidW8Exp',
    NoW9 = 'NoW9',
    ValidW9 = 'ValidW9',
    NoTreatyExists = 'NoTreatyExists',
    TaxTreatyExists = 'TaxTreatyExists',
    NonQiEntity = 'NonQiEntity',
    NonParticipatingForeignFinancialInstitution = 'NonParticipatingForeignFinancialInstitution',
    BeneficialOwnerEntity = 'BeneficialOwnerEntity',
    WithholdingForeignTrust = 'WithholdingForeignTrust',
    Intermediary = 'Intermediary',
    NonQualifiedIntermediary = 'NonQualifiedIntermediary',
    QualifiedIntermediaryAgreement = 'QualifiedIntermediaryAgreement',
    QualifiedIntermediaryBank = 'QualifiedIntermediaryBank',
}

export enum RegulatoryDocumentType {
    W8Ben = 'W8Ben',
    W8BenE = 'W8BenE',
    W8Exp = 'W8Exp',
    W8Imy = 'W8Imy',
    W9 = 'W9',
    ConsentToDisclose = 'ConsentToDisclose',
    QiFatca = 'QiFatca',
    JointAccountStatement = 'JointAccountStatement',
}

export enum FilterOperator {
    Equals = 'Equals',
    NotEquals = 'NotEquals',
    In = 'In',
    NotIn = 'NotIn',
    LessThan = 'LessThan',
    LessThanOrEqualTo = 'LessThanOrEqualTo',
    MoreThan = 'MoreThan',
    MoreThanOrEqualTo = 'MoreThanOrEqualTo',
    StartsWith = 'StartsWith',
    EndsWith = 'EndsWith',
    Contains = 'Contains',
    NotContains = 'NotContains',
    LastXDays = 'LastXDays',
    NextXDays = 'NextXDays',
    LastXHours = 'LastXHours',
    NextXHours = 'NextXHours',
    Matches = 'Matches',
    ContainsAny = 'ContainsAny',
    NotContainsAny = 'NotContainsAny',
    ContainsAll = 'ContainsAll',
    NotContainsAll = 'NotContainsAll',
    ContainsData = 'ContainsData',
    DoesNotContainData = 'DoesNotContainData',
    OlderThanXDays = 'OlderThanXDays',
    ThisYear = 'ThisYear',
    ThisMonth = 'ThisMonth',
    ThisWeek = 'ThisWeek',
}

export enum QueryRollupType {
    Count = 'Count',
    Sum = 'Sum',
    Minimum = 'Minimum',
    Maximum = 'Maximum',
    Average = 'Average',
}

export enum RelationshipLevel {
    AccountHolder = 'AccountHolder',
    Authorised3rdParty = 'Authorised3rdParty',
    Other3rdParty = 'Other3rdParty',
}

export enum ActivityStatusType {
    Draft = 'Draft',
    Active = 'Active',
    Complete = 'Complete',
    Cancelled = 'Cancelled',
    NotRequired = 'NotRequired',
}

export enum RelationshipStatus {
    Inactive = 'Inactive',
    Active = 'Active',
    Draft = 'Draft',
    Proposed = 'Proposed',
}

export enum RAG {
    Red = 'Red',
    Amber = 'Amber',
    Green = 'Green',
}

export enum CountryAssociationType {
    Nationality = 'Nationality',
    Residency = 'Residency',
    TaxResidency = 'TaxResidency',
    ActivityPlace = 'ActivityPlace',
    Referral = 'Referral',
}

export enum EnvelopeStatusType {
    Draft = 'Draft',
    Created = 'Created',
    Sent = 'Sent',
    Delivered = 'Delivered',
    Signed = 'Signed',
    Completed = 'Completed',
    Voided = 'Voided',
}

export enum ClientLifecycleStage {
    Target = 'Target',
    Engaged = 'Engaged',
    Pitching = 'Pitching',
    Disqualified = 'Disqualified',
    Onboarding = 'Onboarding',
    Client = 'Client',
    PotentiallyWithoutContact = 'PotentiallyWithoutContact',
    WithoutContact = 'WithoutContact',
    Dormant = 'Dormant',
    Unwinding = 'Unwinding',
    Switched = 'Switched',
    PendingDeletion = 'PendingDeletion',
    Left = 'Left',
}

export enum ClientStatusType {
    Lead = 'Lead',
    Prospect = 'Prospect',
    Client = 'Client',
    Closed = 'Closed',
    Disqualified = 'Disqualified',
}

export enum CaseStatusType {
    Pending = 'Pending',
    Active = 'Active',
    Complete = 'Complete',
    Cancelled = 'Cancelled',
}

export enum CaseOriginType {
    OnlineChat = 'OnlineChat',
    CustomerSupportNumber = 'CustomerSupportNumber',
    Email = 'Email',
    Other = 'Other',
    CustomerSelfServe = 'CustomerSelfServe',
    Mail = 'Mail',
    Fax = 'Fax',
}

export enum SlaStatusType {
    None = 'None',
    Within = 'Within',
    Approaching = 'Approaching',
    Breached = 'Breached',
}

export enum CompletedStatusType {
    Inactive = 'Inactive',
    Cancelled = 'Cancelled',
    CompletedOnTime = 'CompletedOnTime',
    CompletedOverdue = 'CompletedOverdue',
    DueSoon = 'DueSoon',
    Overdue = 'Overdue',
    DueLater = 'DueLater',
    NoDeadline = 'NoDeadline',
    HasIssue = 'HasIssue',
}

export enum ProcessStatusType {
    Pending = 'Pending',
    Active = 'Active',
    Complete = 'Complete',
    Cancelled = 'Cancelled',
}

export enum InvestmentTimeHorizon {
    ShortTerm = 'ShortTerm',
    MediumTerm = 'MediumTerm',
    LongTerm = 'LongTerm',
}

export enum ServiceType {
    NotApplicable = 'NotApplicable',
    Discretionary = 'Discretionary',
    Advisory = 'Advisory',
    ExecutionOnly = 'ExecutionOnly',
    Other = 'Other',
}

export enum ProductStatus {
    Inactive = 'Inactive',
    Draft = 'Draft',
    Proposed = 'Proposed',
    Active = 'Active',
    Blocked = 'Blocked',
    Cancelled = 'Cancelled',
    Closing = 'Closing',
}

export enum ClientProductReviewStatusType {
    Pending = 'Pending',
    Complete = 'Complete',
}

export enum ProductGroupType {
    Portfolio = 'Portfolio',
}

export enum ProductProviderContext {
    Informed = 'Informed',
    UnderAgency = 'UnderAgency',
    Unknown = 'Unknown',
}

export enum ClientProductType {
    Investment = "Investment",
    Banking = "Banking",
    Portfolio = "Portfolio",
    StockBroking = "StockBroking",
    Insurance = "Insurance",
    Lending = "Lending",
    WealthManagement = "WealthManagement",
    Protection = "Protection",
    Pension = "Pension",
}

export enum UsStatus {
    No = 'No',
    Yes = 'Yes',
    Maybe = 'Maybe',
    Unknown = 'Unknown',
}

export enum BlockedStatusType {
    Unblocked = 'Unblocked',
    Blocked = 'Blocked',
}

export enum ManagingIntermediaryType {
    Relationship = 'Relationship',
    Advice = 'Advice',
    Unknown = 'Unknown',
}

export enum IntroducingIntermediaryType {
    IntroducerOnly = 'IntroducerOnly',
    ContractedIntroducer = 'ContractedIntroducer',
    Unknown = 'Unknown',
}

export enum ClientClassificationType {
    Retail = 'Retail',
    Professional = 'Professional',
    ElectiveProfessional = 'ElectiveProfessional',
    EligibleCounterparty = 'EligibleCounterparty',
    ElectiveEligibleCounterparty = 'ElectiveEligibleCounterparty',
}

export enum CrsStatusType {
    Reportable = 'Reportable',
    NotReportable = 'NotReportable',
}

export enum MifidInvestorType {
    InstitutionalInvestors = 'InstitutionalInvestors',
    ProfessionalInvestors = 'ProfessionalInvestors',
    OtherInvestor = 'OtherInvestor',
}

export enum MifidClientCategory {
    Professional = 'Professional',
    Retail = 'Retail',
    EligibleCounterparty = 'EligibleCounterparty',
}

export enum FinsaClientCategory {
    Professional = 'Professional',
    PrivateClient = 'PrivateClient',
    Institutional = 'Institutional',
}

export enum IntegrationStatusReason {
    Unknown = 'Unknown',
    NetworkError = 'NetworkError',
    Timeout = 'Timeout',
    Rejected = 'Rejected',
}

export enum IntegrationStatusType {
    Unknown = 'Unknown',
    Success = 'Success',
    Failure = 'Failure',
}

export enum DocumentSourceType {
    Received = 'Received',
    Generated = 'Generated',
}

export enum ContactPointType {
    Unknown = 'Unknown',
    Postal = 'Postal',
    Email = 'Email',
    Phone = 'Phone',
    Social = 'Social',
    ElectronicBanking = 'ElectronicBanking',
}

export enum AddressType {
    Residential = 'Residential',
    Business = 'Business',
    Postal = 'Postal',
    POBOX = 'POBOX',
    MailTo = 'MailTo',
    DeliveryTo = 'DeliveryTo',
    Legal = 'Legal',
    RegisteredAddress = 'RegisteredAddress',
}

export enum UserActionEntity {
    Person = 'Person',
    Organisation = 'Organisation',
    Client = 'Client',
    Intermediary = 'Intermediary',
    Prospect = 'Prospect',
    Lead = 'Lead',
    Adviser = 'Adviser',
    Article = 'Article',
    Activity = 'Activity',
    Case = 'Case',
    Opportunity = 'Opportunity',
    ClientProduct = 'ClientProduct',
    ProductProvider = 'ProductProvider',
    List = 'List',
    Construct = 'Construct',
    Campaign = 'Campaign',
    Event = 'Event',
}

export enum PartyType {
    Unknown = 'Unknown',
    Person = 'Person',
    Organisation = 'Organisation',
    User = 'User',
    Team = 'Team',
    Construct = 'Construct',
}

export enum FormActionType {
    SaveDraft = 'SaveDraft',
    Publish = 'Publish',
    Complete = 'Complete',
    Function = 'Function',
}

export enum FormSaveMode {
    ReadOnly = 'ReadOnly',
    Save = 'Save',
    Draft = 'Draft',
    Complete = 'Complete',
}

export enum FormDataStatus {
    Draft = 'Draft',
    Published = 'Published',
    HistoricDraft = 'HistoricDraft',
    HistoricPublished = 'HistoricPublished',
    Confirmed = 'Confirmed',
}

export enum FormElementType {
    Field = 'Field',
    Label = 'Label',
    Button = 'Button',
    Link = 'Link',
    Array = 'Array',
}

export enum FormFieldType {
    Text = 'Text',
    MultilineText = 'MultilineText',
    RichText = 'RichText',
    Json = 'Json',
    DateTime = 'DateTime',
    Date = 'Date',
    Time = 'Time',
    Duration = 'Duration',
    DateRange = 'DateRange',
    DateTimeRange = 'DateTimeRange',
    Select = 'Select',
    Multiselect = 'Multiselect',
    TextDropdown = 'TextDropdown',
    Boolean = 'Boolean',
    Integer = 'Integer',
    Decimal = 'Decimal',
    Currency = 'Currency',
    LookupSingle = 'LookupSingle',
    LookupMulti = 'LookupMulti',
    DocumentLookup = 'DocumentLookup',
    ContactPointLookup = 'ContactPointLookup',
    UrlLookup = 'UrlLookup',
    Hierarchical = 'Hierarchical',
    ImageUpload = 'ImageUpload',
    DocumentUpload = 'DocumentUpload',
    Array = 'Array',
    Address = 'Address',
    PhoneNumber = 'PhoneNumber',
    EmailAddress = 'EmailAddress',
    TagListSingle = 'TagListSingle',
    TagListMulti = 'TagListMulti',
    Password = 'Password',
    Identifier = 'Identifier',
}

export enum PendingChangeStatusType {
    Pending = 'Pending',
    Submitted = 'Submitted',
    Accepted = 'Accepted',
    Applied = 'Applied',
    Rejected = 'Rejected',
    Superceded = 'Superceded',
}

export enum ActivityType {
    Unknown = 'Unknown',
    PhoneCall = 'PhoneCall',
    Email = 'Email',
    Appointment = 'Appointment',
    Task = 'Task',
    WorkItem = 'WorkItem',
    Note = 'Note',
    TimeBooking = 'TimeBooking',
    Glia = 'Glia',
    Qwil = 'Qwil',
    Sms = 'Sms',
    Push = 'Push',
    SecureMessage = 'SecureMessage',
    Unblu = 'Unblu',
    ExternalTask = 'ExternalTask',
}

export enum DataLockLevel {
    None = 'None',
    Full = 'Full',
    Partial = 'Partial',
}

export enum SystemEntity {
    Organisation = "Organisation",
    Person = "Person",
    User = "User",
    Team = "Team",
    Appointment = "Appointment",
    Task = "Task",
    ActivityParty = "ActivityParty",
    ElectronicAddress = "ElectronicAddress",
    PhoneAddress = "PhoneAddress",
    PostalAddress = "PostalAddress",
    PartyRole = "PartyRole",
    PartyRoleAccess = "PartyRoleAccess",
    Relationship = "Relationship",
    Email = "Email",
    PhoneCall = "PhoneCall",
    WebHook = "WebHook",
    Construct = "Construct",
    Process = "Process",
    WorkItem = "WorkItem",
    SocialAddress = "SocialAddress",
    Product = "Product",
    ClientProductRelationship = "ClientProductRelationship",
    ClientProduct = "ClientProduct",
    ProductGroup = "ProductGroup",
    Mandate = "Mandate",
    Objective = "Objective",
    Tag = "Tag",
    Note = "Note",
    Channel = "Channel",
    DocumentAssociation = "DocumentAssociation",
    Client = "Client",
    Intermediary = "Intermediary",
    Salutation = "Salutation",
    AmlReport = "AmlReport",
    FeeStructure = "FeeStructure",
    TimeBooking = "TimeBooking",
    Notification = "Notification",
    AmlRisk = "AmlRisk",
    DataLock = "DataLock",
    RiskLevel = "RiskLevel",
    Comment = "Comment",
    Nudge = "Nudge",
    NudgeLog = "NudgeLog",
    FeedNudge = "FeedNudge",
    Reminder = "Reminder",
    Adviser = "Adviser",
    GoalPoint = "GoalPoint",
    Article = "Article",
    ProductGroupMember = "ProductGroupMember",
    InvestmentAccount = "InvestmentAccount",
    BankAccount = "BankAccount",
    UserView = "UserView",
    Opportunity = "Opportunity",
    MailingInstruction = "MailingInstruction",
    Benchmark = "Benchmark",
    Case = "Case",
    AppStatus = "AppStatus",
    LendingAccount = "LendingAccount",
    InsuranceAccount = "InsuranceAccount",
    PotentialDuplicate = "PotentialDuplicate",
    Engagement = "Engagement",
    Message = "Message",
    ArticleDistribution = "ArticleDistribution",
    DocumentTemplate = "DocumentTemplate",
    QiStatus = "QiStatus",
    QueueItem = "QueueItem",
    CaseTeamMember = "CaseTeamMember",
    List = "List",
    Answer = "Answer",
    Document = "Document",
    GeneratedDocument = "GeneratedDocument",
    Feed = "Feed",
    DocumentRequirementType = "DocumentRequirementType",
    DocumentRequirement = "DocumentRequirement",
    Import = "Import",
    ExternalBankAccount = "ExternalBankAccount",
    ExternalBankAccountAssociation = "ExternalBankAccountAssociation",
    CountryAssociation = "CountryAssociation",
    NationalIdentifier = "NationalIdentifier",
    OpportunityTeamMember = "OpportunityTeamMember",
    Region = "Region",
    Portfolio = "Portfolio",
    Lead = "Lead",
    ClientProductAccess = "ClientProductAccess",
    Export = "Export",
    EdsMessageBatch = "EdsMessageBatch",
    EdsMessage = "EdsMessage",
    MandateGroup = "MandateGroup",
    DocumentType = "DocumentType",
    History = "History",
    Campaign = "Campaign",
    CampaignResponse = "CampaignResponse",
    UserLogin = "UserLogin",
    ViewTile = "ViewTile",
    ExternalUser = "ExternalUser",
    Session = "Session",
    IdentityCheck = "IdentityCheck",
    IdentityCheckReview = "IdentityCheckReview",
    UserDashboard = "UserDashboard",
    ImportLog = "ImportLog",
    Envelope = "Envelope",
    Signatory = "Signatory",
    IdentityMap = "IdentityMap",
    ProductProvider = "ProductProvider",
    WealthManagementAccount = "WealthManagementAccount",
    ProtectionAccount = "ProtectionAccount",
    FinancialRecord = "FinancialRecord",
    FinancialRecordSubType = "FinancialRecordSubType",
    ProductFinancialLink = "ProductFinancialLink",
    PendingChange = "PendingChange",
    PensionAccount = "PensionAccount",
    ListMember = "ListMember",
    ExternalTask = "ExternalTask",
    Block = "Block",
    Event = "Event",
    EventParticipation = "EventParticipation",
    ElectronicBankingAddress = "ElectronicBankingAddress",
    PartySystemConnection = "PartySystemConnection",
    EmploymentHistory = "EmploymentHistory",
    InsiderHistory = "InsiderHistory",
    KycReport = "KycReport",
    StockBrokingAccount = "StockBrokingAccount",
    AppendixEntry = "AppendixEntry",
    AppendixSchema = "AppendixSchema",
    AppendixLayout = "AppendixLayout",
    AppendixCalculation = "AppendixCalculation",
    ScheduledJob = "ScheduledJob",
    FailedJob = "FailedJob",
    SystemLog = "SystemLog",
    AuditEvent = "AuditEvent",
    Country = "Country",
    Currency = "Currency",
    ProductType = "ProductType",
    RoleType = "RoleType",
    RoleSubType = "RoleSubType",
    ContactPoint = "ContactPoint",
    Activity = "Activity",
    Party = "Party",
}

export enum AppType {
    Consent = 'Consent',
    ContactInformation = 'ContactInformation',
    Goals = 'Goals',
    SourceOfWealth = 'SourceOfWealth',
    AttitudeToRisk = 'AttitudeToRisk',
    Knowledge = 'Knowledge',
    CommercialContext = 'CommercialContext',
    OrganisationFinancials = 'OrganisationFinancials',
    WealthAndIncome = 'WealthAndIncome',
    ClientRoles = 'ClientRoles',
    Family = 'Family',
    Employment = 'Employment',
    Tax = 'Tax',
    InterestsAndHobbies = 'InterestsAndHobbies',
    InvestorRiskProfile = 'InvestorRiskProfile',
    PersonBusinessRisk = 'PersonBusinessRisk',
    OrganisationBusinessRisk = 'OrganisationBusinessRisk',
    Esg = 'Esg',
    Employees = 'Employees',
    MailingInstructions = 'MailingInstructions',
    EstatePlanning = 'EstatePlanning',
    Protection = 'Protection',
    ClientSummary = 'ClientSummary',
    ProductSelection = 'ProductSelection',
    PersonDetails = 'PersonDetails',
    OrganisationDetails = 'OrganisationDetails',
    ClientCommunication = 'ClientCommunication',
    ProfessionalConnections = 'ProfessionalConnections',
    AmlRisk = 'AmlRisk',
    OrganisationEmirFmia = 'OrganisationEmirFmia',
    OrganisationSourceOfWealth = 'OrganisationSourceOfWealth',
    PartyBankAccount = 'PartyBankAccount',
    ClientBankAccount = 'ClientBankAccount',
    BusinessRiskClient = 'BusinessRiskClient',
    TaxClient = 'TaxClient',
    OrganisationTax = 'OrganisationTax',
    AboutYou = 'AboutYou',
    Kyc = 'Kyc',
    ExternalUser = 'ExternalUser',
    OrganisationConnections = 'OrganisationConnections',
    Powers = 'Powers',
    PendingChanges = 'PendingChanges',
    Review = 'Review',
    OxfordRisk = 'OxfordRisk',
    ClientProductRelationships = 'ClientProductRelationships',
    AdverseMediaCheck = 'AdverseMediaCheck',
    CorporateStructure = 'CorporateStructure',
    PepAndSanctions = 'PepAndSanctions',
    CoreScreening = 'CoreScreening',
    IdentityVerification = 'IdentityVerification',
    ClientRiskAssessment = 'ClientRiskAssessment',
    NationalityAndResidence = 'NationalityAndResidence',
    Portfolio = 'Portfolio',
    Investment = 'Investment',
    Insurance = 'Insurance',
    Lending = 'Lending',
    Banking = 'Banking',
    PortfolioInstructions = 'PortfolioInstructions',
    ProductSetup = 'ProductSetup',
    ProductDetails = 'ProductDetails',
    ProcessData = 'ProcessData',
    DocumentChecklist = 'DocumentChecklist',
    MandatoryDocumentChecklist = 'MandatoryDocumentChecklist',
    CaseDocumentChecklist = 'CaseDocumentChecklist',
    Appendix = 'Appendix',
    Testing = 'Testing',
    Group = 'Group',
    NotApplicable = 'NotApplicable',
}

export enum AppStatusType {
    Incomplete = 'Incomplete',
    PendingConfirmation = 'PendingConfirmation',
    NearingExpiration = 'NearingExpiration',
    Expired = 'Expired',
    Confirmed = 'Confirmed',
    Closed = 'Closed',
    Dormant = 'Dormant',
    Inactive = 'Inactive',
}

export enum DataChangeActionType {
    Update = 'Update',
    Add = 'Add',
    Remove = 'Remove',
}

export enum DataChangeType {
    Manual = 'Manual',
    Calculated = 'Calculated',
}

export enum DateRestrictionType {
    None = 'None',
    HistoricOnly = 'HistoricOnly',
    FutureOnly = 'FutureOnly',
}

export enum LookupSourceType {
    None = 'None',
    Any = 'Any',
    Party = 'Party',
    Person = 'Person',
    ExternalUser = 'ExternalUser',
    Organisation = 'Organisation',
    User = 'User',
    Team = 'Team',
    RootTeam = 'RootTeam',
    BookingCentre = 'BookingCentre',
    ServicingCentre = 'ServicingCentre',
    Construct = 'Construct',
    ClientProduct = 'ClientProduct',
    ClientProductLive = 'ClientProductLive',
    ClientProductLiveNotClosing = 'ClientProductLiveNotClosing',
    Portfolio = 'Portfolio',
    InvestmentAccount = 'InvestmentAccount',
    BankingAccount = 'BankingAccount',
    InsuranceAccount = 'InsuranceAccount',
    LendingAccount = 'LendingAccount',
    PensionAccount = 'PensionAccount',
    WealthManagementAccount = 'WealthManagementAccount',
    ExternalBankAccount = 'ExternalBankAccount',
    ProductGroup = 'ProductGroup',
    Adviser = 'Adviser',
    Intermediary = 'Intermediary',
    ProductProvider = 'ProductProvider',
    Company = 'Company',
    Charity = 'Charity',
    Trust = 'Trust',
    Collective = 'Collective',
    Corporate = 'Corporate',
    Client = 'Client',
    Case = 'Case',
    Process = 'Process',
    PartyRole = 'PartyRole',
    PartyRoleParty = 'PartyRoleParty',
    PersonPartyRole = 'PersonPartyRole',
    OrganisationPartyRole = 'OrganisationPartyRole',
    Opportunity = 'Opportunity',
    Activity = 'Activity',
    List = 'List',
    Campaign = 'Campaign',
    Event = 'Event',
    ContactPoint = 'ContactPoint',
    ElectronicAddress = 'ElectronicAddress',
    PhoneAddress = 'PhoneAddress',
    MobileAddress = 'MobileAddress',
    PostalAddress = 'PostalAddress',
    SocialAddress = 'SocialAddress',
    ElectronicBankingAddress = 'ElectronicBankingAddress',
    Assignee = 'Assignee',
    Document = 'Document',
    DocumentTemplate = 'DocumentTemplate',
    DocumentRequirement = 'DocumentRequirement',
    EdsMessageBatch = 'EdsMessageBatch',
    Import = 'Import',
    Envelope = 'Envelope',
    Signatory = 'Signatory',
    AccountHolders = 'AccountHolders',
    AccountHolderEmails = 'AccountHolderEmails',
    AccountHolderAddresses = 'AccountHolderAddresses',
    EligibleClient = 'EligibleClient',
    ClientRelationship = 'ClientRelationship',
    ClientRelationshipChild = 'ClientRelationshipChild',
    Association = 'Association',
    Foundation = 'Foundation',
    LifeInsuranceWrapper = 'LifeInsuranceWrapper',
    AmlReport = 'AmlReport',
    Block = 'Block',
    PersonalRelatedPeople = 'PersonalRelatedPeople',
}

export enum FormElementStyle {
    None = 'None',
    Dropdown = 'Dropdown',
    Checkboxes = 'Checkboxes',
    CheckTiles = 'CheckTiles',
    Radios = 'Radios',
    RadioTiles = 'RadioTiles',
    ButtonGroup = 'ButtonGroup',
    StartAndEnd = 'StartAndEnd',
    StartAndDuration = 'StartAndDuration',
    StartOnly = 'StartOnly',
    DayOfYear = 'DayOfYear',
    MonthAndYearOnly = 'MonthAndYearOnly',
    YearOnly = 'YearOnly',
}

export enum SelectApiSource {
    None = "None",
    Activity = "Activity",
    AmlRisk = "AmlRisk",
    BankingProduct = "BankingProduct",
    Benchmark = "Benchmark",
    CaseType = "CaseType",
    Channel = "Channel",
    ClientType = "ClientType",
    ClientSubType = "ClientSubType",
    Country = "Country",
    CountryReferral = "CountryReferral",
    Currency = "Currency",
    DocumentType = "DocumentType",
    ClientProductDocumentTypes = "ClientProductDocumentTypes",
    MailingInstructionDocumentTypes = "MailingInstructionDocumentTypes",
    DocumentRequirementType = "DocumentRequirementType",
    EconomicActivity = "EconomicActivity",
    FamilyRelationshipType = "FamilyRelationshipType",
    FeeStructure = "FeeStructure",
    InsuranceProduct = "InsuranceProduct",
    InvestmentProduct = "InvestmentProduct",
    PensionProduct = "PensionProduct",
    Language = "Language",
    LendingProduct = "LendingProduct",
    WealthManagementProduct = "WealthManagementProduct",
    Mandate = "Mandate",
    MandateGroup = "MandateGroup",
    NationalIdentifierType = "NationalIdentifierType",
    NudgeType = "NudgeType",
    Objective = "Objective",
    OrganisationClientType = "OrganisationClientType",
    OrganisationRelationshipType = "OrganisationRelationshipType",
    OrganisationToPersonRelationshipType = "OrganisationToPersonRelationshipType",
    OrganisationToPersonEmploymentRoles = "OrganisationToPersonEmploymentRoles",
    OrganisationType = "OrganisationType",
    PersonType = "PersonType",
    PipelineStages = "PipelineStages",
    PortfolioProduct = "PortfolioProduct",
    ProductParentsOnly = "ProductParentsOnly",
    Product = "Product",
    ProductFormDefinition = "ProductFormDefinition",
    ProductType = "ProductType",
    ProfessionalRelationshipType = "ProfessionalRelationshipType",
    RelationshipType = "RelationshipType",
    RelationshipToChild = "RelationshipToChild",
    RelationshipToParent = "RelationshipToParent",
    QiStatus = "QiStatus",
    QueryRollupColumn = "QueryRollupColumn",
    Region = "Region",
    RiskLevel = "RiskLevel",
    Role = "Role",
    RoleSubType = "RoleSubType",
    TeamType = "TeamType",
    Salutation = "Salutation",
    SystemEntity = "SystemEntity",
    TagCategory = "TagCategory",
    Tenant = "Tenant",
    Title = "Title",
    UserRole = "UserRole",
    View = "View",
    ViewForList = "ViewForList",
    RoleContactPlays = "RoleContactPlays",
    AssetTypes = "AssetTypes",
    LiabilityTypes = "LiabilityTypes",
    IncomeTypes = "IncomeTypes",
    ExpenseTypes = "ExpenseTypes",
    ProtectionTypes = "ProtectionTypes",
    PersonalWealthSourceTypes = "PersonalWealthSourceTypes",
    InstitutionalWealthSourceTypes = "InstitutionalWealthSourceTypes",
    FundsOriginTypes = "FundsOriginTypes",
    ParentProductCode = "ParentProductCode",
    SourceSystems = "SourceSystems",
    ProcessTypeCode = "ProcessTypeCode",
    WorkItemTypeCode = "WorkItemTypeCode",
}

export enum TagCategory {
    MarketingCampaigns = "MarketingCampaigns",
    Interests = "Interests",
    Restrictons = "Restrictons",
    InvestmentProducts = "InvestmentProducts",
    InvestmentThemes = "InvestmentThemes",
    InvestmentThemesCountry = "InvestmentThemesCountry",
    ActivityReasons = "ActivityReasons",
    TaskCategories = "TaskCategories",
    NetWorth = "NetWorth",
    Other = "Other",
}

export enum LookupCreationMode {
    ExistingOnly = 'ExistingOnly',
    CreateOnly = 'CreateOnly',
    CreateAndExisting = 'CreateAndExisting',
}

export enum LookupResultsMode {
    Default = 'Default',
    Immediate = 'Immediate',
}

export enum LookupContextMode {
    SearchPreservesContext = 'SearchPreservesContext',
    SearchIgnoresContext = 'SearchIgnoresContext',
}

export enum DocumentTypeStatusType {
    Inactive = 'Inactive',
    Active = 'Active',
    Draft = 'Draft',
    Invalid = 'Invalid',
}

export enum SummaryLevel {
    InfoCard = 'InfoCard',
    Title = 'Title',
    SubTitle1 = 'SubTitle1',
    SubTitle2 = 'SubTitle2',
    SubTitle3 = 'SubTitle3',
    IsPrimary = 'IsPrimary',
}

export enum PhoneType {
    Unknown = 'Unknown',
    Home = 'Home',
    Work = 'Work',
    Other = 'Other',
    MobilePersonal = 'MobilePersonal',
    MobileWork = 'MobileWork',
    MobileOther = 'MobileOther',
    FaxHome = 'FaxHome',
    FaxWork = 'FaxWork',
    FaxOther = 'FaxOther',
}

export enum EntityPermissionType {
    View = 'View',
    Create = 'Create',
    Edit = 'Edit',
    Delete = 'Delete',
    Reactivate = 'Reactivate',
    ManageRecordAccess = 'ManageRecordAccess',
    File = 'File',
}

export enum StatusType {
    Inactive = 'Inactive',
    Active = 'Active',
}

export enum HistoryEventType {
    Created = 'Created',
    Updated = 'Updated',
    Deleted = 'Deleted',
    Assigned = 'Assigned',
    Comment = 'Comment',
    Started = 'Started',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
    StatusChanged = 'StatusChanged',
    Activated = 'Activated',
    Inactivated = 'Inactivated',
    Linked = 'Linked',
    Unlinked = 'Unlinked',
    Distributed = 'Distributed',
    Seen = 'Seen',
    Viewed = 'Viewed',
    Published = 'Published',
    Archived = 'Archived',
    SetToDraft = 'SetToDraft',
    Liked = 'Liked',
    Unliked = 'Unliked',
    DueDateSet = 'DueDateSet',
    Ended = 'Ended',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Transferred = 'Transferred',
    CobrowsingStarted = 'CobrowsingStarted',
    CobrowsingEnded = 'CobrowsingEnded',
    CommentAdded = 'CommentAdded',
    IssueAdded = 'IssueAdded',
    IssueResolved = 'IssueResolved',
    PromotedToLead = 'PromotedToLead',
    Engaged = 'Engaged',
    Pitching = 'Pitching',
    Disqualified = 'Disqualified',
    Onboarding = 'Onboarding',
    PromotedClient = 'PromotedClient',
    Advocate = 'Advocate',
    FlightRisk = 'FlightRisk',
    Switched = 'Switched',
    Blocked = 'Blocked',
    Left = 'Left',
    ClientStatusCleared = 'ClientStatusCleared',
    Generated = 'Generated',
    Provided = 'Provided',
    Received = 'Received',
    ApprovedWithConditions = 'ApprovedWithConditions',
    PotentiallyWithoutContact = 'PotentiallyWithoutContact',
    WithoutContact = 'WithoutContact',
    Dormant = 'Dormant',
    Submitted = 'Submitted',
    Confirmed = 'Confirmed',
    MadeReadyForSubmission = 'MadeReadyForSubmission',
    MadeCannotStart = 'MadeCannotStart',
    MadeReadyToStart = 'MadeReadyToStart',
    NearlyExpired = 'NearlyExpired',
    Expired = 'Expired',
    ReadyToBeConfirmed = 'ReadyToBeConfirmed',
    MadeIncomplete = 'MadeIncomplete',
    Error = 'Error',
    PendingDeletion = 'PendingDeletion',
    ExternalUserUpdatedForm = 'ExternalUserUpdatedForm',
    ExternalUserSubmittedForm = 'ExternalUserSubmittedForm',
    ExternalUserAddedDocument = 'ExternalUserAddedDocument',
    ExternalUserSubmittedDocument = 'ExternalUserSubmittedDocument',
}

export enum ActionType {
    Patch = 'Patch',
    Form = 'Form',
}

export enum FatcaClassification {
    UsAccount = 'UsAccount',
    Recalcitrant = 'Recalcitrant',
    PassiveNffe = 'PassiveNffe',
    OwnerDocumentedFfi = 'OwnerDocumentedFfi',
    NonUsFfi = 'NonUsFfi',
    NonUsOrNonReportable = 'NonUsOrNonReportable',
    NonParticipatingFfi = 'NonParticipatingFfi',
}

export enum UsAccountType {
    IndividualUsAccount = 'IndividualUsAccount',
    IndividualNonUsAccount = 'IndividualNonUsAccount',
    IndividualNonConsentingUsAccount = 'IndividualNonConsentingUsAccount',
    CorporationUsAccount = 'CorporationUsAccount',
    CorporationNonUsAccount = 'CorporationNonUsAccount',
    CorporationNonConsentingUsAccount = 'CorporationNonConsentingUsAccount',
    CorporationNonConsentingNonParticipatingFi = 'CorporationNonConsentingNonParticipatingFi',
    NonConsentingWithoutDocumentation = 'NonConsentingWithoutDocumentation',
    IndividualUsAccountAsn = 'IndividualUsAccountAsn',
    IndividualUsAccountNonConsenting = 'IndividualUsAccountNonConsenting',
    IncorrectDocExceptionW8Ben = 'IncorrectDocExceptionW8Ben',
    IncorrectDocInternal = 'IncorrectDocInternal',
    NoDocumentationInternal = 'NoDocumentationInternal',
    OutOfScope = 'OutOfScope',
    LiquidatedCompany = 'LiquidatedCompany',
    NonFinancialAccount = 'NonFinancialAccount',
    ToHandle = 'ToHandle',
}

export enum CrsEntityType {
    Individual = 'Individual',
    Exempt = 'Exempt',
    FinancialInstitution = 'FinancialInstitution',
    ForeignFinancialInstitution = 'ForeignFinancialInstitution',
    ActiveNonFinancial = 'ActiveNonFinancial',
    PassiveNonFinancial = 'PassiveNonFinancial',
}

/**
 *
 *            888
 *            888
 *            888
 *    .d8888b 888888 .d88b. 88888b.
 *    88K     888   d88""88b888 "88b
 *    "Y8888b.888   888  888888  888
 *         X88Y88b. Y88..88P888 d88P
 *     88888P' "Y888 "Y88P" 88888P"
 *                          888
 *                          888
 *                          888
 *
 *  Do not manually edit this file, if you need to update it see instructions in README.md (at root of repo)
 *
 *  Any changes you manually make will get lost when someone else updates it properly!
 *
 */
